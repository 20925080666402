import { Box, Chip, Typography } from "@mui/material";
import DashboardLayout from "../../layout";
import DTable from "../Dashboard/Table";
import TableSearch from "shared/TableSearch";
import { useNavigate } from "react-router-dom";
import ReUseAbleTable, { Column } from "shared/ReUseAbleTable";

export default function BookingRide() {

    const navigate = useNavigate()

    const columns: Column[] = [
        { id: "from", label: "From", minWidth: 100 },
        { id: "to", label: "To", minWidth: 100 },
        {
            id: "eta", label: "ETA", minWidth: 100, format: (value: any) => (
                <Box>
                    <Typography color="#344054" fontSize={14}>{value.date}</Typography>
                    <Typography color="#344054" fontSize={14}>{value.time}</Typography>
                </Box>
            )
        },
        {
            id: "status", label: "Status", minWidth: 72, format: (value: any) => (
                <Chip label={value} size="small" sx={{ bgcolor: "#E8FFF2", color: "#379E66", fontSize: "14px" }} />
            )
        },
    ];

    const rows = [
        {
            id: 1,
            from: "Nnamdi  Azikwe International Airport",
            to: "Ikeja, Lagos",
            eta: { date: "7 July 2024", time: "10:00 AM" },
            status: "En Route",
        },
        {
            id: 11,
            from: "Federal Camp Otuke",
            to: "Kule, Abuja",
            eta: { date: "7 July 2024", time: "10:00 AM" },
            status: "En Route",
        },
        {
            id: 121,
            from: "Nnamdi  Azikwe International Airport",
            to: "Onitsha, Anambra",
            eta: { date: "7 July 2024", time: "10:00 AM" },
            status: "Completed",
        },
    ];
    return (
        <DashboardLayout nextSideBar>
            <Box sx={{ width: "100%" }}>
                <Box mb="24px" display="flex" gap="10px">
                    <div>
                        <Typography color="#101828" fontSize="24px" fontWeight={600}>
                            Manage Bookings
                        </Typography>
                    </div>
                </Box>
                <TableSearch noBgColor p='0px' searchPlaceHolder="Booking ID, User, Flight/Ride ..." />

                <ReUseAbleTable columns={columns} rows={rows} onClick={(row) => navigate('/bookings/rideorderdetails', { state: { row } })} />
            </Box>
        </DashboardLayout>
    )
}
