import TableContainer from '@mui/material/TableContainer';

import Paper from '@mui/material/Paper';
import { Avatar, Box, Checkbox, Typography } from '@mui/material';
import TableSearch from './TableSearch';

import { ReactNode } from 'react';

import ReUseAbleTable, { Column } from 'shared/ReUseAbleTable';

export default function OrderTable({
  noTableSearch,
  title,
}: {
  noTableSearch?: boolean;
  title?: ReactNode;
}) {
  const columns: Column[] = [
    {
      id: 'name',
      label: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Typography>Name</Typography>
        </Box>
      ),
    },
    { id: 'email', label: 'Email' },
    { id: 'phone', label: 'Phone Number' },
    { id: 'total', label: 'Total Amount paid' },
  ];

  const rows = [
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
    {
      id: 1,
      name: (
        <Box display='flex' alignItems='center'>
          <Checkbox />
          <Avatar sx={{ mr: 1 }}>J</Avatar>
          <Typography sx={{ fontSize: '12px' }}>John Doe</Typography>
        </Box>
      ),
      email: 'johndoe@example.com',
      phone: '09066530997',
      total: '$250',
    },
  ];

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ borderRadius: '10px', border: '1px solid #E4E7EC' }}
    >
      {!noTableSearch && <TableSearch />}
      <Box width='100%'>{title}</Box>
      <ReUseAbleTable columns={columns} rows={rows} />
    </TableContainer>
  );
}
