import { Box, Typography } from "@mui/material";

import carImg from "../assets/vehicle-car.png";

const VehicleCard = ({
  vehicleImg,
  onClick,
}: {
  vehicleImg?: string;
  onClick: () => void;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={0.5}
      p={2}
      borderRadius="8px"
      width="100%"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
      height="100%"
    >
      <Box width="30%">
        <img width="74px" src={vehicleImg || carImg} alt="car-img" />
      </Box>
      <Box width="70%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography color="#667085" fontSize="10px">
              Vehicle
            </Typography>

            <Typography fontWeight={600} color="#101828" fontSize="12px">
              Toyota Sienna
            </Typography>
          </Box>
          <Box>
            <Typography color="#667085" fontSize="10px">
              License plate
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              KRD792JF
            </Typography>
          </Box>
        </Box>
        <Box height="24px" />
        <Box
          mt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography color="#667085" fontSize="10px">
              Driver
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              Mr Adetola
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography color="#667085" fontSize="10px">
              Phone
            </Typography>
            <Typography fontWeight={600} color="#101828" fontSize="12px">
              081078493792
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleCard;
