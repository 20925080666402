import React, { useState } from "react";
import DashboardLayout from "../../layout";
import DashboardTopTitle from "../../components/DashboardTopTitle";

import Box from "@mui/material/Box";

import Profile from "./SubCards/Profile";
import Admins from "./SubCards/Admins";
import Payouts from "./SubCards/Payouts";
import ReUseAbleTabs from "../../shared/ReUseAbleTabs";

const tabs = [
  { label: "Profile", path: "/settings/profile", Component: <Profile /> },
  { label: "Admin", path: "/settings/admin", Component: <Admins /> },
  {
    label: "Payout",
    path: "/settings/payout",
    Component: <Payouts />,
  },
];

const Settings = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title="Settings"
          subtitle="Your current sales summary and activity."
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "auto" }}>
          <Box sx={{ borderBottom: "1px solid #E4E7EC" }}>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Settings;
