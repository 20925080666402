import {
  Avatar,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ReUseAbleTextField from "../../../shared/ReUseAbleTextField";

const Information = () => {
  return (
    <Box>
      <Box height="120px" display="grid" gridTemplateColumns="1fr 1fr">
        <div style={{ display: "flex", alignItems: "center", gap: "41px" }}>
          <Avatar sx={{ height: "120px", width: "120px" }} />
          <Box>
            <Typography fontSize={16} fontWeight={600} color="#344054">
              John Doe
            </Typography>
            <Typography fontSize={14}>ID: 784893</Typography>
            <Typography
              fontSize={14}
              display="flex"
              alignItems="center"
              gap="4px"
            >
              4.7 <Rating /> (70)
            </Typography>
            <Typography fontSize={14}>
              KYC Level 1 Completed . 0814 609 2019
            </Typography>
          </Box>
        </div>
        <Typography fontSize={14}>Referral Code: weooeio</Typography>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <Typography fontSize={16} fontWeight={600}>
            Profile details
          </Typography>
          <Typography fontSize={14}>
            Update your personal details here.
          </Typography>
        </div>
        <Box width="707px">
          <form autoComplete="off">
            <ReUseAbleTextField
              title="Address"
              placeholder="Address"
              type="text"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Location"
                placeholder="Location"
                type="text"
              />
              <ReUseAbleTextField
                title="State"
                placeholder="State"
                type="text"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Birthday"
                placeholder="Birthday"
                type="text"
              />
              <ReUseAbleTextField
                title="Username"
                placeholder="Username"
                type="text"
              />
            </Box>
          </form>
        </Box>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <Typography fontSize={16} fontWeight={600}>
            Profile details
          </Typography>
          <Typography fontSize={14}>
            Update your personal details here.
          </Typography>
        </div>
        <Box width="707px">
          <form autoComplete="off">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Identity Type"
                placeholder="Identity Type"
                type="text"
              />
              <ReUseAbleTextField
                title="Identity No."
                placeholder="Identity No."
                type="text"
              />
            </Box>
            <ReUseAbleTextField
              title="Address"
              placeholder="Address"
              type="text"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Location"
                placeholder="Location"
                type="text"
              />
              <ReUseAbleTextField
                title="State"
                placeholder="State"
                type="text"
              />
              <ReUseAbleTextField
                title="Country"
                placeholder="Country"
                type="text"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Date of Birth"
                placeholder="Date of Birth"
                type="text"
              />
              <ReUseAbleTextField title="NIN" placeholder="NIN" type="text" />
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Information;
