import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Typography } from "@mui/material";
import { UnfoldMore } from "@mui/icons-material";

function createData(
  date: string,
  amount: string,
  transactionID: string,
  description: string,
  payMtd: string,
  status: string
) {
  return { date, amount, transactionID, description, payMtd, status };
}
const rows = [
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
];

export default function TransactionTable() {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "none" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell width="201px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="#344054" fontSize={12}>
                  Date
                </Typography>
                <UnfoldMore />
              </Box>
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="174px"
            >
              Amount
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="263.5px"
            >
              Transaction ID
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="263.5px"
            >
              Description
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="263.5px"
            >
              Payments Method
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.date}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" width="201px">
                <Typography color="#475367" fontSize={14}>
                  {row.date}
                </Typography>
              </TableCell>

              <TableCell align="left" width="174px">
                <Typography color="#344054" fontSize={14}>
                  {row.amount}
                </Typography>
              </TableCell>

              <TableCell align="left" width="263.5px">
                <Typography color="#344054" fontSize={14}>
                  {row.transactionID}
                </Typography>
              </TableCell>
              <TableCell align="left" width="263.5px">
                <Typography color="#344054" fontSize={14}>
                  {row.description}
                </Typography>
              </TableCell>
              <TableCell align="left" width="166px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.payMtd}
                  </Typography>
                  <Typography color="#667185" fontSize={14}>
                    {row.status}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
