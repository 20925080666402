import React from "react";
import DashboardLayout from "../layout";
import Box from "@mui/material/Box";

const Promo = () => {
  return (
    <DashboardLayout>
      <Box>Promo</Box>
    </DashboardLayout>
  );
};

export default Promo;
