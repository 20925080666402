import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { MoreVert, UnfoldMore } from "@mui/icons-material";

function createData(
  name: string,
  email: string,
  dateAdded: string,
  role: string,
  lastLogin: string
) {
  return { name, email, dateAdded, role, lastLogin };
}
const rows = [
  createData(
    "Jaydon Korsguard",
    "jaydonkorsquard@gmail.com",
    "Apr 12, 2023 | 09:32 AM",
    "Super Admin",
    "Apr 12, 2023 | 09:32 AM"
  ),
  createData(
    "Haylle Workman",
    "haylle_w@gmail.com",
    "Apr 12, 2023 | 09:32 AM",
    "Customer success",
    "Apr 12, 2023 | 09:32 AM"
  ),
  createData(
    "Kierra Rosser",
    "kierraroser@gmail.com",
    "Apr 12, 2023 | 09:32 AM",
    "Finance",
    "Apr 12, 2023 | 09:32 AM"
  ),
  createData(
    "Cheyenne Mango",
    "cheyennemango@gmail.com",
    "Apr 12, 2023 | 09:32 AM",
    "Admin",
    "Apr 12, 2023 | 09:32 AM"
  ),
];

export default function AdminTable() {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell width="371px">
              <Typography color="#344054" fontSize={12}>
                Name
              </Typography>
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="200px"
            >
              Date added
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="200px"
            >
              Role
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="200px"
            >
              Last Login
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="78px"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" width="371px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Avatar />
                  <div>
                    <Typography color="#101928" fontSize={14} fontWeight={500}>
                      {row.name}
                    </Typography>
                    <Typography color="#475367" fontSize={14}>
                      {row.email}
                    </Typography>
                  </div>
                </Box>
              </TableCell>

              <TableCell align="left" width="200px">
                <Typography color="#344054" fontSize={14}>
                  {row.dateAdded}
                </Typography>
              </TableCell>
              <TableCell align="left" width="199px">
                <Chip
                  size="small"
                  sx={{ color: "#344054", fontSize: "14px" }}
                  label={row.role}
                />
              </TableCell>
              <TableCell align="left" width="200px">
                <Typography color="#344054" fontSize={14}>
                  {row.lastLogin}
                </Typography>
              </TableCell>
              <TableCell align="left" width="78px">
                <MoreVert />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
