import { Box, Button, TextField, Typography, InputLabel } from '@mui/material'
import DashboardTopTitle from 'components/DashboardTopTitle'
import DashboardLayout from 'layout'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import StateChangerUI from 'shared/StateChangerUI'

export default function PriceEstimator() {

    const { control, handleSubmit } = useForm({})

    return (
        <DashboardLayout nextSideBar subMenu goTo='/dashboard?view=ride'>
            <DashboardTopTitle title='Ride Price Estimator' />

            <StateChangerUI />

            <Box p='32px 128px 32px 32px' bgcolor='white'>
                <Typography fontSize={20} fontWeight={700}>Driver & Vehicle's Information</Typography>

                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Pickup Location
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Destination
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Time (1)
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Price
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>

                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Time (2)
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} />
                    </Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Price
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Time (3)
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} />
                    </Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Estimated Price
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='center' mt='24px'>
                    <Button sx={{ width: '300px', p: '10px 14px', borderRadius: '8px', fontWeight: 400 }} variant='contained'>Save</Button>
                </Box>
            </Box>

            <Box bgcolor='white' p='20px 16px' my={4} border='1px solid #E8FFF2' borderRadius='8px' width='400px'>
                <Box border='1px solid #E8FFF2' p='16px 12px' borderRadius='8px'>
                    <Typography fontWeight={600} fontSize='20px'>Pricing Tier</Typography>

                    <Typography fontSize='14px' color='#475467'>20-30 mins: #10,000</Typography>
                    <Typography fontSize='14px' color='#475467'>30-40 mins: #20,000</Typography>
                    <Typography fontSize='14px' color='#475467'>40-60 mins: #30,000</Typography>
                </Box>
            </Box>

        </DashboardLayout>
    )
}
