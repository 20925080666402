import React, { useRef, useState } from 'react';
import DashboardLayout from '../layout';
import Box from '@mui/material/Box';
import DashboardTopTitle from '../components/DashboardTopTitle';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import DashboardTableEvents from '../components/DashboardTableEvents';
import { Button, Chip, Typography } from '@mui/material';
import bus from '../assets/bus.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as OrderArrow } from '../assets/order-arrow.svg';
import Round from '../assets/round.png';
import Flash from '../assets/flash.png';
import Exclusive from '../assets/exclusive.png';
import { Scheduler } from '@aldabil/react-scheduler';
import type { SchedulerRef } from '@aldabil/react-scheduler/types';

const Events = () => {
  const navigate = useNavigate();
  const [exclusive, setExclusive] = useState(false);
  const [sharp, setSharp] = useState(false);
  const calendarRef = useRef<SchedulerRef>(null);

  const changeView = (view: 'day' | 'week' | 'month') => {
    if (calendarRef.current) {
      calendarRef.current.scheduler.handleState(view, 'view');
    }
  };

  const goToToday = () => {
    if (calendarRef.current) {
      calendarRef.current.scheduler.handleGotoDay(new Date());
    }
  };

  const goToPrevious = () => {
    if (calendarRef.current) {
      calendarRef.current.scheduler.handleState('view', 'day');
    }
  };

  const goToNext = () => {
    if (calendarRef.current) {
      calendarRef.current.scheduler.handleState('view', 'day');
    }
  };

  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title='Events'
          onClickFirst={() => {}}
          secondBtnIcon={<AddCircleOutlineRounded />}
          secondBtnTitle='New Appointments'
          secondBtn
          onClickSecond={() => {
            if (calendarRef.current) {
              calendarRef.current.scheduler.triggerDialog(true, {
                start: new Date(), // Replace with desired start date
                end: new Date(new Date().setDate(new Date().getDate() + 1)), // Replace with desired end date
              });
            }
          }}
        />

        <DashboardTableEvents
          onClickDays={() => changeView('day')}
          onClickMonth={() => changeView('month')}
          onClickWeek={() => changeView('week')}
          goToNext={goToNext}
          goToPrev={goToPrevious}
          goToToday={goToToday}
        >
          {sharp ? (
            <Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap='8px'>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((_) => (
                <Box
                  border='1px solid #E4E7EC'
                  borderRadius='8px'
                  p='12px'
                  height='162px'
                  width='358.67px'
                  bgcolor='#FFFFFF'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate('/order-details');
                  }}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <div>
                      <Typography
                        fontWeight={700}
                        color='#379E66'
                        fontSize={12}
                      >
                        6:45am
                      </Typography>
                      <Typography fontSize={12}>
                        Young Legacy Park,
                        <br /> Iyana- Ipaja
                      </Typography>
                      <Typography fontSize={12} color='#98A2B3'>
                        Lagos
                      </Typography>
                    </div>
                    <OrderArrow />
                    <div style={{ textAlign: 'right' }}>
                      <Typography
                        fontWeight={700}
                        color='#379E66'
                        fontSize={12}
                      >
                        12:03pm
                      </Typography>
                      <Typography fontSize={12}>
                        Abukbakar Gamjuma,
                        <br /> Market Park
                      </Typography>
                      <Typography fontSize={12} color='#98A2B3'>
                        Ilorin
                      </Typography>
                    </div>
                  </Box>
                  <div
                    style={{
                      gap: '8px',
                      alignItems: 'center',
                      display: 'flex',
                      marginTop: '8px',
                      marginBottom: '8px',
                    }}
                  >
                    <img src={bus} alt='' />
                    <Chip
                      sx={{
                        fontWeight: 600,
                      }}
                      label='4 seats left'
                      size='small'
                    />
                    <Chip
                      sx={{
                        fontWeight: 600,
                      }}
                      label='12 seater'
                      size='small'
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography fontSize={12} fontWeight={700}>
                      NGN 35,895 - 40,904
                    </Typography>
                    <div
                      style={{
                        gap: '8px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        sx={{
                          bgcolor: '#FFFAEB',
                          color: '#B54708',
                          fontWeight: 600,
                        }}
                        label='2km'
                        size='small'
                        icon={<img src={Flash} height='12px' width='12px' />}
                      />
                      {exclusive ? (
                        <Chip
                          sx={{
                            bgcolor: '#EEF4FF',
                            color: '#3538CD',
                            fontWeight: 600,
                          }}
                          label='Standard'
                          size='small'
                          icon={<img src={Round} height='12px' width='12px' />}
                        />
                      ) : (
                        <Chip
                          sx={{
                            bgcolor: '#FDF2FA',
                            color: '#C11574',
                            fontWeight: 600,
                          }}
                          label='Exclusive'
                          size='small'
                          icon={
                            <img src={Exclusive} height='12px' width='12px' />
                          }
                        />
                      )}
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          ) : (
            <>
              <Scheduler
                ref={calendarRef}
                navigation={true}
                view='day'
                agenda={false}
                week={{
                  weekDays: [0, 1, 2, 3, 4, 5, 6],
                  weekStartOn: 1,
                  startHour: 8,
                  endHour: 16,
                  step: 60,
                  navigation: true,
                }}
                day={{
                  startHour: 8,
                  endHour: 16,
                  step: 60,
                  navigation: true,
                }}
                hourFormat='24'
                events={[
                  {
                    event_id: 1,
                    title: 'Event 1',
                    start: new Date('2021/5/2 09:30'),
                    end: new Date('2021/5/2 10:30'),
                  },
                  {
                    event_id: 2,
                    title: 'Event 2',
                    start: new Date('2021/5/4 10:00'),
                    end: new Date('2021/5/4 11:00'),
                  },
                ]}
              />
            </>
          )}
        </DashboardTableEvents>
      </Box>
    </DashboardLayout>
  );
};

export default Events;
