import { Box, Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { CustomIcon } from "../components/DashboardTableEvents";
import {
  ArrowDropDownOutlined,
  CalendarMonthOutlined,
  List,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import SelectDate from "../components/SelectDate";

const TrackCard = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Box bgcolor="#FFFFFF" p="24px">
      <Box px="12px">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="12px"
          >
            <CustomIcon
              iconLeft={<CalendarMonthOutlined />}
              iconRight={<List />}
            />
            <Typography
              border="1px solid #D0D5DD"
              borderRadius="8px"
              p="8px 16px"
              fontSize={14}
              fontWeight={600}
            >
              Today
            </Typography>

            <CustomIcon
              iconLeft={<NavigateBefore />}
              iconRight={<NavigateNext />}
            />
          </Box>
          <Typography fontSize={18}>July, 2023</Typography>
          <SelectDate />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={14}
            style={{
              display: "flex",
              alignContent: "center",
              gap: 4,
            }}
          >
            Find by{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#379E66",
                fontSize: 14,
                display: "flex",
                alignContent: "center",
              }}
            >
              Event <ArrowDropDownOutlined />
            </span>
          </Typography>
        </div>
        <form
          action=""
          style={{
            display: "flex",
            alignItems: "center",
            gap: "18px",
            marginTop: "12px",
          }}
        >
          <TextField
            placeholder="From"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              bgcolor: "#F9F9F9",
              mt: 2,
              mb: 2,
              p: "10px 14px",
              borderRadius: "10px",
              width: "422.5px",
            }}
            type="text"
          />
          <TextField
            placeholder="To where"
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              bgcolor: "#F9F9F9",
              mt: 2,
              mb: 2,
              p: "10px 14px",
              borderRadius: "10px",
              width: "422.5px",
            }}
            type="text"
          />
          <Button
            sx={{
              bgcolor: "#000000",
              color: "#FFFFFF",
              width: "171px",
              p: "6.75px 11.25px",
              height: "50px",
              borderRadius: "3.38px",
              fontWeight: 400,
              fontSize: "14.06px",
            }}
            variant="contained"
            onClick={onClick}
          >
            Find Bus
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default TrackCard;
