import Card from "@mui/material/Card";
import { keyframes } from "@emotion/react";
import { ErrorOutline } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React from "react";

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
`;
interface AlertMessageProps {
  title: string;
  icon: React.ReactNode;
  bgColor: string;
  textColor: string;
}

const AlertMessage = ({
  title,
  icon,
  bgColor,
  textColor,
}: AlertMessageProps) => {
  return (
    <Card
      variant="elevation"
      sx={{
        backgroundColor: bgColor,
        color: textColor,
        width: "100%",
        animation: `${slideIn} 2s ease-in-out`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        position: "absolute",
        top: 0,
        borderRadius: 0,
        p: 1,
      }}
    >
      {icon}
      <Typography fontSize={14}> {title}</Typography>
    </Card>
  );
};

export default AlertMessage;
