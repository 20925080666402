import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import ReUseAbleTextField from "../../../shared/ReUseAbleTextField";

const MedicalInfo = () => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <Typography fontSize={16} fontWeight={600}>
            Medical Info
          </Typography>
          <Typography fontSize={14}>
            Update your personal details here.
          </Typography>
        </div>
        <Box width="707px">
          <form autoComplete="off">
            <ReUseAbleTextField
              title="Address"
              placeholder="Address"
              type="text"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Location"
                placeholder="Location"
                type="text"
              />
              <ReUseAbleTextField
                title="State"
                placeholder="State"
                type="text"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Contact Information"
                placeholder="Contact Information"
                type="text"
              />
              <ReUseAbleTextField title="Role" placeholder="Role" type="text" />
            </Box>
          </form>
        </Box>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div>
          <Typography fontSize={16} fontWeight={600}>
            Next of KIN Details
          </Typography>
          <Typography fontSize={14}>
            Update your personal details here.
          </Typography>
        </div>
        <Box width="707px">
          <form autoComplete="off">
            <ReUseAbleTextField
              title="Emergency Info"
              placeholder="Emergency Info"
              type="text"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
            >
              <ReUseAbleTextField
                title="Contact Information"
                placeholder="Contact Information"
                type="text"
              />
              <ReUseAbleTextField title="Role" placeholder="Role" type="text" />
            </Box>
            <ReUseAbleTextField
              title="Home Address"
              placeholder="Home Address"
              type="text"
            />
            <ReUseAbleTextField
              title="Email address"
              placeholder="Email address"
              type="text"
            />
            <ReUseAbleTextField
              title="Phone Number"
              placeholder="Phone Number"
              type="text"
            />
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default MedicalInfo;
