import { Box } from '@mui/material';
import React from 'react';
import ReUseAbleTabs from 'shared/ReUseAbleTabs';

import DashboardLayout from 'layout';
import DashboardTopTitle from 'components/DashboardTopTitle';
import {
  AddCircleOutline,
  CloudDownloadOutlined,
  Download,
} from '@mui/icons-material';
import Information from './SubCards/Information';
import Transactions from './SubCards/Transactions';
import Insights from './SubCards/Insights';
import { useNavigate } from 'react-router-dom';
import ParkAssigned from './SubCards/ParkAssigned';
import Events from './SubCards/Events';
import Travels from './SubCards/Travels';
import Orders from './SubCards/Orders';

const tabs = [
  {
    label: 'Information',
    path: '/park-managers/sub/',
    Component: <Information />,
  },
  {
    label: 'Insights',
    path: '/park-managers/sub/insight',
    Component: <Insights />,
  },
  {
    label: 'Park Assigned',
    path: '/park-managers/sub/park-assigned',
    Component: <ParkAssigned />,
  },
  {
    label: 'Events',
    path: '/park-managers/sub/events',
    Component: <Events />,
  },
  {
    label: 'Orders',
    path: '/park-managers/sub/orders',
    Component: <Orders />,
  },
  {
    label: 'Transactions',
    path: '/park-managers/sub/transactions',
    Component: <Transactions />,
  },
  {
    label: 'Travels',
    path: '/park-managers/sub/travels',
    Component: <Travels />,
  },
];
const ParkManagerSub = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: 'auto' }}>
          <Box>
            <DashboardTopTitle
              title='Manager Details'
              subtitle='Showing data over the last 30 days.'
              onClickFirst={() => {}}
              firstBtnIcon={<CloudDownloadOutlined />}
              firstBtnTitle='Export CSV'
              firstBtn
              secondBtn
              secondBtnIcon={<AddCircleOutline />}
              secondBtnTitle='Blacklist User'
              secondBgColor='#DA4733'
              onClickSecond={() => navigate('/create-park-manager')}
            />
          </Box>
          <Box>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default ParkManagerSub;
