import { useState } from "react";
import DashboardLayout from "../../layout";
import Box from "@mui/material/Box";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import { Add, CloudUploadOutlined } from "@mui/icons-material";
import TrackCard from "../../shared/TrackCard";
import TravelRegister from "../TravelRegister";
import { useNavigate } from "react-router-dom";
const Tracks = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title="Track"
          subtitle="Your current sales summary and activity."
          onClickFirst={() => {}}
          firstBtnIcon={<CloudUploadOutlined />}
          firstBtnTitle="Export CSV"
          firstBtn
          secondBtn
          secondBtnIcon={<Add />}
          secondBtnTitle='Invite'
        />

        <TrackCard
          onClick={() => {
            navigate("/travel-register");
          }}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Tracks;
