import { Add, MoreVert } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import ReUseAbleTabs from "shared/ReUseAbleTabs";
import TheCharts from "shared/TheCharts";

const tabs = [
  { Component: <TheCharts />, path: "", label: "Traffic channel" },
  { Component: <TheCharts />, path: "", label: "Sources" },
  { Component: <TheCharts />, path: "", label: "Referrals" },
];

const TheChart = () => {
  return (
    <Box
      width="100%"
      height="auto"
      p="24px"
      borderRadius="12px"
      border="1px solid #E4E7EC"
      bgcolor="#FFFFFF"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontWeight={600} fontSize={18}>
          How do you acquire users?
        </Typography>

        <MoreVert />
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Box>
        <ReUseAbleTabs tabs={tabs} />
      </Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex">
          <div
            style={{
              padding: "8px 16px",
              fontSize: "12px",
              border: "1px solid #D0D5DD",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              backgroundColor: "#F9FAFB",
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#182230">
              12 months
            </Typography>
          </div>
          <div
            style={{
              padding: "8px 16px",
              border: "1px solid #D0D5DD",
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#182230">
              30 days
            </Typography>
          </div>
          <div
            style={{
              padding: "8px 16px",
              border: "1px solid #D0D5DD",
            }}
          >
            <Typography fontSize={14} fontWeight={600} color="#182230">
              7 days
            </Typography>
          </div>
          <div
            style={{
              padding: "8px 16px",
              border: "1px solid #D0D5DD",
              display: "flex",
              gap: "8px",
              alignItems: "center",
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <Add />
            <Typography fontSize={14} fontWeight={600} color="#182230">
              Custom
            </Typography>
          </div>
        </Box>
        <div
          style={{
            padding: "8px 16px",
            border: "1px solid #D0D5DD",

            borderRadius: "10px",
          }}
        >
          <Typography fontSize={14} fontWeight={600} color="#182230">
            Audience overview
          </Typography>
        </div>
      </div>
    </Box>
  );
};

export default TheChart;
