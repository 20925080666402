import { Box, List, ListItem } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LegendProps,
} from "recharts";

interface DataPoint {
  name: string;
  uv: number;
  pv: number;
  mv: number;
}

const data: DataPoint[] = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    mv: 5000,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    mv: 5000,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    mv: 5000,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    mv: 5000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    mv: 5000,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    mv: 5000,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
  {
    name: "Aug",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
  {
    name: "Sep",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
  {
    name: "Oct",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
  {
    name: "Dec",
    uv: 3490,
    pv: 4300,
    mv: 5000,
  },
];

const CustomLegend: React.FC<LegendProps> = (props) => {
  const { payload } = props;

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {payload?.reverse().map((entry, index) => (
        <ListItem
          key={`item-${index}`}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Box
            height={8}
            width={8}
            borderRadius="100%"
            bgcolor={entry.color}
            marginRight={1}
          />
          <span style={{ fontFamily: "Sora", fontSize: "12px" }}>
            {entry.value === "pv"
              ? "Referral"
              : entry.value === "uv"
              ? "Organic search"
              : "Direct"}
          </span>
        </ListItem>
      ))}
    </List>
  );
};

const TheCharts = () => {
  const chartStyle = { fontFamily: "Sora", fontSize: "12px" };
  return (
    <div>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={chartStyle}
        >
          <XAxis dataKey="name" tickLine={false} />

          <Tooltip />
          <CartesianGrid horizontal={true} vertical={false} />
          <Legend
            verticalAlign="top"
            align="right"
            content={<CustomLegend />}
          />
          <Bar
            dataKey="pv"
            stackId="a"
            fill="#379E66"
            radius={[10, 10, 0, 0]}
            // background={{ fill: "#379E66" }}
            barSize={32}
          />
          <Bar
            dataKey="uv"
            stackId="a"
            fill="#46BD7C"
            radius={[10, 10, 0, 0]}
            barSize={32}
          />
          <Bar
            dataKey="mv"
            stackId="a"
            fill="#E4E7EC"
            radius={[10, 10, 0, 0]}
            barSize={32}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TheCharts;
