import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import CustomTable from "../../../components/CustomTable";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import {
  AddCircleOutline,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import TableSearch from "shared/TableSearch";

const Transactions = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Transaction Activities"
        onClickFirst={() => {}}
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="New Appointment"
        secondBtn
      />
      <TransactionTable onClick={() => {}} />
    </Box>
  );
};

export default Transactions;

function createData(
  date: string,
  amount: string,
  transId: string,
  desc: string,
  payment: string,
  method: "Successful" | "Pending"
) {
  return {
    date,
    amount,
    transId,
    desc,
    payment,
    method,
  };
}
const rows = [
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin ",
    "Cash",
    "Successful"
  ),
];

const TransactionTable = ({ onClick }: { onClick: () => void }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "0px 0px 10px 10px", border: "1px solid #E4E7EC" }}
    >
      <TableSearch />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F0F2F5" }}>
          <TableRow>
            <TableCell height="45px" width="311px">
              <Box>
                <Typography color="#344054" fontSize={12}>
                  Date
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              Amount
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="242px"
            >
              Transaction ID
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="188px"
            >
              Description
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              Payment Method
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ borderBottom: "1px solid #e4e7ec" }}>
          {rows.map((row) => (
            <TableRow
              key={row.transId}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.date}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="242px">
                <Typography color="#344054" fontSize={14}>
                  {row.amount}
                </Typography>
              </TableCell>
              <TableCell align="left" width="188px">
                <Typography color="#344054" fontSize={14}>
                  {row.transId}
                </Typography>
              </TableCell>
              <TableCell align="left" width="166px">
                <Typography color="#344054" fontSize={14}>
                  {row.desc}
                </Typography>
              </TableCell>
              <TableCell align="left" width="72px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.payment}
                  </Typography>
                  <Typography color="#344054" fontSize={14}>
                    {row.method}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableCell align="center" width="72px">
            <Box display="flex" alignItems="center" justifyContent="center">
              <ChevronLeft />
              <Typography>1</Typography>
              <Typography>2</Typography>
              <Typography>3</Typography>
              <Typography>4</Typography>
              <ChevronRight />
            </Box>
          </TableCell>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
