import { FormControl, InputLabel, TextField } from "@mui/material";
import React from "react";

interface TheInputProps {
  title: string;
  onChange?: () => void;
  value?: string;
  placeholder: string;
  type: "text" | "number" | "email" | "password";
  icon: React.ReactNode;
}

const SettingsTextField = ({
  title,
  onChange,
  value,
  placeholder,
  type,
  icon,
}: TheInputProps) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel shrink sx={{ fontWeight: 800 }}>
        {title}
      </InputLabel>
      <TextField
        placeholder={placeholder}
        type={type}
        variant="standard"
        value={value}
        InputProps={{ disableUnderline: true, startAdornment: icon }}
        sx={{
          bgcolor: "transparent",
          borderRadius: "10px",
          border: "1px solid #D0D5DD",
          mt: 2.5,
          py: 1,
          px: 2,
        }}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default SettingsTextField;
