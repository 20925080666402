import { Box, Chip, Typography } from '@mui/material';

import { flightShowUps } from './data/data';
import { motion } from 'framer-motion';
import AppTable from './Table';
import { getStatusStyle } from 'utils/misc';

const DashboardFlight = () => {
  const headers = [
    { label: 'Booking ID', key: 'bookingID' },
    { label: 'From', key: 'from' },
    { label: 'To', key: 'to' },
    { label: 'Contact', key: 'contact' }, // Nested for email and phone
    { label: 'Amount', key: 'amount' },
    { label: 'Status', key: 'status' },
    { label: 'Date and Time', key: 'dateTime' }, // Nested for date and time
  ];

  const data = [
    {
      bookingID: 'LAGJ-9080',
      from: 'Young Legacy Park, Iyana',
      to: 'Adukbakar Gamjuma Market Park',
      contact: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>Jdoe@gmail.com</Typography>
          <Typography sx={{ fontSize: '12px' }}>08106748972</Typography>
        </Box>
      ),
      amount: 'NGN 1,000000',
      status: <Chip label='Pending' sx={getStatusStyle('Pending')} />,
      dateTime: (
        <Box sx={{ fontSize: '12px' }}>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2023</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
    },
    {
      bookingID: 'LAGJ-9080',
      from: 'Young Legacy Park, Iyana',
      to: 'Adukbakar Gamjuma Market Park',
      contact: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>Jdoe@gmail.com</Typography>
          <Typography sx={{ fontSize: '12px' }}>08106748972</Typography>
        </Box>
      ),
      amount: 'NGN 1,000000',
      status: <Chip label='Failed' sx={getStatusStyle('Failed')} />,
      dateTime: (
        <Box sx={{ fontSize: '12px' }}>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2023</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
    },
    {
      bookingID: 'LAGJ-9080',
      from: 'Young Legacy Park, Iyana',
      to: 'Adukbakar Gamjuma Market Park',
      contact: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>Jdoe@gmail.com</Typography>
          <Typography sx={{ fontSize: '12px' }}>08106748972</Typography>
        </Box>
      ),
      amount: 'NGN 1,000000',
      status: <Chip label='Expired' sx={getStatusStyle('Expired')} />,
      dateTime: (
        <Box sx={{ fontSize: '12px' }}>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2023</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
    },
    {
      bookingID: 'LAGJ-9080',
      from: 'Young Legacy Park, Iyana',
      to: 'Adukbakar Gamjuma Market Park',
      contact: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>Jdoe@gmail.com</Typography>
          <Typography sx={{ fontSize: '12px' }}>08106748972</Typography>
        </Box>
      ),
      amount: 'NGN 1,000000',
      status: <Chip label='Pending' sx={getStatusStyle('Pending')} />,
      dateTime: (
        <Box sx={{ fontSize: '12px' }}>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2023</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
    },
  ];
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box width='100%'>
          <Box
            display='grid'
            gridTemplateColumns={{ md: '1fr 1fr 1fr 1fr', xs: '1fr' }}
            gap='30px'
          >
            {flightShowUps.map((items) => (
              <motion.button
                whileHover={{ scale: 1.0 }}
                whileTap={{ scale: 0.9 }}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '12px',
                  border: '1px solid #E4E7EC',
                  padding: '16px',
                }}
              >
                <Box
                  display='flex'
                  alignItems='start'
                  justifyContent='space-between'
                >
                  <Box>
                    <Typography
                      fontSize='16px'
                      fontWeight={600}
                      color='#202224'
                    >
                      {items.title}
                    </Typography>
                    <Typography mt='7px' fontSize='30px' fontWeight={700}>
                      {items.value}
                    </Typography>
                  </Box>
                  <Box
                    bgcolor={items.bg}
                    borderRadius='100%'
                    height='60px'
                    width='60px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <img
                      height='32.73px'
                      width='32.73px'
                      src={items.icon}
                      alt={items.title}
                    />
                  </Box>
                </Box>
                <Typography
                  fontSize='14px'
                  fontWeight={600}
                  mt='14px'
                  sx={{ display: 'flex', gap: '2px' }}
                >
                  <img height='24px' width='24px' src={items.arrow} alt='alt' />
                  <span style={{ color: items.aColor || '#379E66' }}>
                    {items.rating}%
                  </span>
                  <span style={{ color: '#98A2B3', fontSize: '13px' }}>
                    {items.res}
                  </span>
                </Typography>
              </motion.button>
            ))}
          </Box>
        </Box>
        <Box height='24px' />
        <AppTable headers={headers} data={data} />
      </Box>
    </>
  );
};

export default DashboardFlight;
