import { Box } from '@mui/material'
import React from 'react'
import ReUseAbleTabs from 'shared/ReUseAbleTabs'

import DashboardLayout from 'layout';
import DashboardTopTitle from 'components/DashboardTopTitle';
import { AddCircleOutline, Download } from '@mui/icons-material';
import Information from './SubCards/Information';
import MedicalInfo from './SubCards/MedicalInfo';
import Transactions from './SubCards/Transactions';
import Insights from './SubCards/Insights';
import SubTravels from './SubCards/SubTravels';
import { useNavigate } from 'react-router-dom';


const tabs = [
  {
    label: "Information",
    path: "/customers/sub/",
    Component: <Information />,
  },
  { label: "Insights", path: "/customers/sub/insights", Component: <Insights /> },
  {
    label: "Medical Info",
    path: "/customers/sub/medical-info",
    Component: <MedicalInfo />,
  },
  {
    label: "Transactions",
    path: "/customers/sub/transactions",
    Component: <Transactions />,
  },
  {
    label: "Travels",
    path: "/customers/sub/travels",
    Component: <SubTravels />,
  },
];

const CustomersSub = () => {
  const navigate = useNavigate()
  return (
    <DashboardLayout>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "auto" }}>
          <Box>
            <DashboardTopTitle
              title="Customers"
              subtitle="Showing data over the last 30 days."
              onClickFirst={() => { }}
              firstBtnIcon={<Download />}
              firstBtnTitle="Export CSV"
              secondBtnIcon={<AddCircleOutline />}
              secondBtnTitle="Add Employee"
              firstBtn
              secondBtn
              onClickSecond={() => navigate("/create-customers")}
            />

          </Box>
          <Box>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default CustomersSub
