import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import Bus from '../../assets/bus.svg';
import React, { useState } from 'react';
import link from '../../assets/link.svg';
import airPlane from '../../assets/airplane.png';
import carImage from '../../assets/carImage.jpg';
import { Add, CloudUploadOutlined } from '@mui/icons-material';
import DashboardLayout from 'layout';
import ReUseAbleTabs from 'shared/ReUseAbleTabs';
import DashboardTopTitle from 'components/DashboardTopTitle';
import OrderTable from 'shared/OrderTable';
import { useLocation } from 'react-router-dom';

const BookingOrderDetails = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const location = useLocation();
  const row = location.state?.row;

  const checkStatus = localStorage.getItem('dashboardView');

  const tabs = [
    {
      label: 'Flight Details',
      path: '/bookings/orderdetails/flightdetails',
      Component: <FlightDetails row={row} />,
    },
    {
      label: 'Passengers',
      path: '/bookings/orderdetails/passengers',
      Component: <Passengers />,
    },
  ];

  return (
    <DashboardLayout
      subMenu
      goTo={checkStatus === '1' ? '/bookings/flight' : '/bookings/ride'}
      nextSideBar
    >
      <DashboardTopTitle title='Order Details (B142356)' />

      <Box border='1px solid #E4E7EC' width='100%' borderRadius='12px'>
        <Box
          height='80px'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          bgcolor='#F0F2F5'
          sx={{
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
            p: '16px',
          }}
        >
          <Box display='flex' alignItems='center' gap='12px'>
            <div
              style={{
                height: '48px',
                width: '48px',
                borderRadius: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#B5E3C4',
              }}
            >
              <img src={Bus} alt='' color='#379E66' />
            </div>
            <div>
              <Typography fontSize={14} fontWeight={600}>
                Ilorin - Abuja
              </Typography>
              <Typography fontSize={14}>B142356</Typography>
            </div>
          </Box>
          <Chip
            label={'None'}
            size='small'
            sx={{ bgcolor: '#1671D9', color: '#FFF' }}
          />
        </Box>

        <Box
          p='16px'
          borderBottom='1px solid #E4E7EC'
          height='112px'
          bgcolor='#FFF'
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <Typography
                sx={{ color: '#475367', fontWeight: 400 }}
                fontSize={14}
              >
                From
              </Typography>
              <Typography fontSize={14}>
                General Tunde Idiagbon International Airport, Ilorin
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography
                sx={{ color: '#475367', fontWeight: 400 }}
                fontSize={14}
              >
                To
              </Typography>
              <Typography fontSize={14}>
                Nnamdi Azikwe International Airport, Abuja
              </Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography
                sx={{ color: '#475367', fontWeight: 400 }}
                fontSize={14}
              >
                Time of departure
              </Typography>
              <Typography fontSize={14}>12:34</Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography
                sx={{ color: '#475367', fontWeight: 400 }}
                fontSize={14}
              >
                Time of Arrival
              </Typography>
              <Typography fontSize={14}>16:54 (3hrs)</Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <AvatarGroup total={9}>
                <Avatar />
                <Avatar />
                <Avatar />
                <Avatar />
                <Avatar />
              </AvatarGroup>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            borderBottomRightRadius: '12px',
            borderBottomLeftRadius: '12px',
          }}
          bgcolor='#FFF'
          display='flex'
          alignItems='center'
          justifyContent='center'
          height='49px'
        >
          <Button
            variant='text'
            sx={{
              color: '#379E66',
              fontSize: '12px',
              fontWeight: 600,
            }}
          >
            Track this event
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }} mt='24px'>
        <Box sx={{ width: 'auto' }}>
          <Box sx={{ borderBottom: '1px solid #E4E7EC' }}>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default BookingOrderDetails;

const FlightDetails = ({ row }: { row: any }) => {
  const checkStatus = localStorage.getItem('dashboardView');

  return (
    <Box>
      <Box display='grid' gridTemplateColumns='55% 45%' gap={2}>
        <Box>
          <div>
            <Typography color='#475367' fontSize={14}>
              Airline Name
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <Typography fontSize={16}>Arik Air</Typography>
            </div>
          </div>
          <Divider sx={{ my: '12px' }} />
          <Box display='flex' gap={2}>
            <div>
              <Typography color='#475367' fontSize={14}>
                Flight Number
              </Typography>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <Typography fontSize={16}>B123643</Typography>
                <img src={link} alt='' />
              </div>
            </div>
            <div
              style={{ borderLeft: '1px solid #E4E7EC', paddingLeft: '12px' }}
            >
              <Typography color='#475367' fontSize={14}>
                Class
              </Typography>

              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <Typography fontSize={16}>Economy</Typography>
              </div>
            </div>
          </Box>

          <Divider sx={{ my: '12px' }} />
          <Box display='grid' gridTemplateColumns='1fr 1fr'>
            <div>
              <Typography color='#475367' fontSize={14}>
                Departure Airport
              </Typography>

              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <Typography fontSize='16px'>
                  General Tunde Idiagbon International Airport, Ilorin
                </Typography>
              </div>
            </div>
            <div
              style={{ borderLeft: '1px solid #E4E7EC', paddingLeft: '12px' }}
            >
              <Typography color='#475367' fontSize={14}>
                Arrival Airport
              </Typography>

              <div
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                <Typography fontSize='16px'>
                  Nnamdi Azikwe International Airport, Abuja
                </Typography>
              </div>
            </div>
          </Box>
          <Box sx={{ my: '12px' }} />
          <Typography color='#475367' fontSize={14}>
            Baggage Information
          </Typography>
          <Typography fontSize={16}>15kg</Typography>
        </Box>
        <Box>
          <Box p='8px' bgcolor='#F0F2F5' borderRadius='12px'>
            <img
              src={checkStatus === '1' ? airPlane : carImage}
              alt=''
              width='100%'
              style={{ objectFit: 'cover', borderRadius: '12px' }}
              height='244px'
            />

            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography color='#667185' fontSize={16}>
                Total Flight Cost
              </Typography>
              <Typography fontSize='20px' fontWeight={600}>
                NGN 419,090.00
              </Typography>
            </Box>
            <Divider sx={{ mt: '4px' }} />
            <Box
              height='49px'
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Button
                variant='text'
                sx={{
                  color: '#379E66',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
              >
                View Breakdown
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Passengers = () => {
  return (
    <Box>
      <DashboardTopTitle
        title='Passengers'
        onClickFirst={() => {}}
        firstBtnIcon={<CloudUploadOutlined />}
        firstBtnTitle='Export list'
        firstBtn
        secondBtn
        secondBtnIcon={<Add />}
        secondBtnTitle='Invite'
      />
      <OrderTable />
    </Box>
  );
};
