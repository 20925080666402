import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Typography } from "@mui/material";
import { UnfoldMore } from "@mui/icons-material";

function createData(
  name: string,
  position: string,
  message: string,
  content: string,
  date: string,
  time: string
) {
  return { name, position, message, content, date, time };
}
const rows = [
  createData(
    "Alison Ogaga",
    "Park manager",
    "I'm feeling uneasy",
    "Good morning. Im sorry to hear that youre not feeling well. Can you tell me more about your symptoms? How long have you been feeling this way?",
    "1 Jul, 2023",
    "1:00 PM"
  ),
  createData(
    "Jennifer Johnson",
    "Customer",
    "I have a rash",
    "Let's run some tests to understand better what might be causing these symptoms.",
    "1 Jul, 2023",
    "1:00 PM"
  ),
  createData(
    "Samuel Smith",
    "Customer",
    "I’m suffering from anxiety",
    "Good afternoon. It's important that we address this. Anxiety can significantly impact your quality of life. Let's explore some strategies and perhaps treatments that can help you manage your anxiety effectively.",
    "1 Jul, 2023",
    "1:00 PM"
  ),
  createData(
    "Lily Chen",
    "Admin",
    "I have a rash",
    "Hello, let's take a look at that rash. I'll also ask you some questions about your symptoms and any potential allergens or irritants you might have been exposed to.",
    "1 Jul, 2023",
    "1:00 PM"
  ),
];

export default function MessageTable() {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell width="260px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="#344054" fontSize={12}>
                  Name
                </Typography>
                <UnfoldMore />
              </Box>
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="723px"
            >
              Conversation
            </TableCell>
            <TableCell
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              Date and Time
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" width="260px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Avatar />
                  <div>
                    <Typography color="#101928" fontSize={14}>
                      {row.name}
                    </Typography>
                    <Typography color="#475367" fontSize={14}>
                      {row.position}
                    </Typography>
                  </div>
                </Box>
              </TableCell>

              <TableCell align="left" width="723px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.message}
                  </Typography>
                  <Typography color="#667185" fontSize={14}>
                    {row.content}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.date}
                  </Typography>
                  <Typography color="#667185" fontSize={14}>
                    {row.time}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
