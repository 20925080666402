import React from "react";
import SettingsButton from "../SettingsButton";
import { Box, Button, Chip, Grid, Slider, Typography } from "@mui/material";

import {
  Add,
  ChevronRight,
  Close,
  Email,
  PersonOutline,
} from "@mui/icons-material";
import access from "../../../assets/access.svg";
const Payouts = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box
            p="24px"
            border="1px solid #E4E7EC"
            bgcolor="#FFFFFF"
            borderRadius="12px"
          >
            <Typography fontSize={20} fontWeight={600} color="#1A1A21">
              Profile Settings
            </Typography>
            <Typography color="#8C94A6" fontSize={14} mt="8px">
              These are your personal details, they are visible to the public
            </Typography>
            <PayOutCards showPrimary />
            <PayOutCards />
            <PayOutCards />
            <Button
              sx={{
                bgcolor: "#E8FFF2",
                color: "#000000",
                borderRadius: "8px",
                p: "16px 24px",
              }}
              fullWidth
              startIcon={<Add />}
            >
              Add New
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Payouts;

const PayOutCards = ({ showPrimary }: { showPrimary?: boolean }) => {
  return (
    <Box my="16px" p="16px" borderRadius="10px" border="1px solid #D0D5DD">
      {showPrimary && (
        <Typography color="#98A2B3" fontSize={9}>
          PRIMARY BANK ACCOUNT
        </Typography>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
      >
        <img src={access} alt="" style={{ width: "26px", height: "28.54px" }} />
        <div style={{ width: "100%" }}>
          <Typography fontSize={14} color="#1D2739">
            John doe
          </Typography>

          <Typography fontSize={14} color="#98A2B3">
            .... 9872
          </Typography>
        </div>
        <ChevronRight />
      </Box>
    </Box>
  );
};
