import { Avatar, AvatarGroup, Box, Chip, Typography } from "@mui/material";
import React from "react";
import DashboardTableEvents from "../../../components/DashboardTableEvents";
import { AddCircleOutline } from "@mui/icons-material";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import ReUseAbleTabs from "shared/ReUseAbleTabs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const tabs = [
  {
    label: "On-going Travels",
    path: "/customers/travels/on-going-travels",
    Component: <OnGoingTravelsTable onClick={() => {}} />,
  },
  {
    label: "All Travels",
    path: "/customers/travels/all-travels",
    Component: <OnGoingTravelsTable onClick={() => {}} />,
  },
  {
    label: "Past Travels",
    path: "/customers/travels/past-travels",
    Component: <Avatar />,
  },
];

const SubTravels = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Travels"
        onClickFirst={() => {}}
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="New Appointment"
        secondBtn
      />

      <ReUseAbleTabs tabs={tabs} />
    </Box>
  );
};
export default SubTravels;

function createData(
  travelID: string,
  from: string,
  to: string,
  status: string,
  etaDay: string,
  etaTime: string
) {
  return {
    travelID,
    from,
    to,

    status,
    etaDay,
    etaTime,
  };
}

const rows = [
  createData(
    "LAGJ-9080",
    "Young Legacy Park, Iyana",
    "Adukbakar Gamjuma Market Park",
    "En Route",
    "7 July 2024",
    "10: 00 AM"
  ),
];

function OnGoingTravelsTable({ onClick }: { onClick: () => void }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <DashboardTableEvents />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              Travel ID
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="242px"
            >
              From
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="188px"
            >
              To
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              Passengers
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              Status
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              ETA
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.travelID}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.travelID}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="242px">
                <Typography color="#344054" fontSize={14}>
                  {row.from}
                </Typography>
              </TableCell>
              <TableCell align="left" width="188px">
                <Typography color="#344054" fontSize={14}>
                  {row.to}
                </Typography>
              </TableCell>
              <TableCell align="left" width="166px">
                <AvatarGroup>
                  <Avatar sx={{ height: "24px", width: "24px" }} />
                  <Avatar sx={{ height: "24px", width: "24px" }} />
                  <Avatar sx={{ height: "24px", width: "24px" }} />
                  <Avatar sx={{ height: "24px", width: "24px" }} />
                  <Avatar sx={{ height: "24px", width: "24px" }} />
                </AvatarGroup>
              </TableCell>
              <TableCell align="left" width="72px">
                <Chip
                  label={row.status}
                  size="small"
                  sx={{
                    bgcolor: "#E8FFF2",
                    color: "#379E66",
                    fontSize: "14px",
                  }}
                />
              </TableCell>
              <TableCell align="left" width="166px">
                <Typography color="#344054" fontSize={14}>
                  {row.etaDay}
                </Typography>
                <Typography color="#344054" fontSize={14}>
                  {row.etaTime}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
