import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabData {
  label: string;
  Component: React.ReactNode;
  path: string;
}

interface ReUseAbleTabsProps {
  tabs: TabData[];
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReUseAbleTabs = ({ tabs }: ReUseAbleTabsProps) => {
  const [value, setValue] = useState(() => {
    const currentPath = window.location.pathname;
    const currentTabIndex = tabs.findIndex((tab) => tab.path === currentPath);
    return currentTabIndex !== -1 ? currentTabIndex : 0;
  });

  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname;
      const currentTabIndex = tabs.findIndex((tab) => tab.path === currentPath);
      setValue(currentTabIndex !== -1 ? currentTabIndex : 0);
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [tabs]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState(null, "", tabs[newValue].path);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "auto" }}>
        <Box sx={{ borderBottom: "1px solid #E4E7EC" }}>
          <Tabs value={value} onChange={handleChange}>
            {tabs.map(({ label }, i) => (
              <Tab
                sx={{ textTransform: "capitalize" }}
                key={i}
                label={label}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </Box>
        <Box height="auto" bgcolor="#fff">
          {tabs.map(({ Component }, i) => (
            <CustomTabPanel value={value} index={i} key={i}>
              {Component}
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ReUseAbleTabs;
