import toast from 'react-hot-toast';

export const handleMutationError = (err: any, data?: any): void => {
  toast.error(() => {
    const errorData = err?.response?.data?.[data];

    if (errorData?.error) {
      return makeErrorMessageReadable(errorData.error);
    } else if (errorData?.message) {
      return makeErrorMessageReadable(errorData.message);
    } else {
      return err?.response?.data?.message || 'An unexpected error occurred.';
    }
  });
};

function makeErrorMessageReadable(errorMessage: string): string {
  // Extract the field name, number, and the rest of the message
  const regex = /"(.+)" length must be (\d+) characters long/;
  const match = errorMessage.match(regex);

  if (match) {
    const field = match[1];
    const length = match[2];
    return `${capitalize(field)} must be ${length} characters long.`;
  }

  // Return the original message if it doesn't match the expected format
  return errorMessage;
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
