import React, { useState } from "react";

import { AddCircleOutline, CloudDownloadOutlined } from "@mui/icons-material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import OrderTable from "../../../shared/OrderTable";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Orders = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box height="24px" />
      <DashboardTopTitle
        title="Orders"
        firstBtnIcon={<CloudDownloadOutlined />}
        firstBtnTitle="Export list"
        firstBtn
        secondBtn
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="Invite"
      />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "auto" }}>
          <Box>
            <Tabs value={value} onChange={handleChange}>
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="New Orders"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="All Orders"
                {...a11yProps(1)}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="Past Orders"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <Box
            border="1px solid #F0F2F5"
            height="auto"
            borderRadius="10px"
            bgcolor="#FFFFFF"
          >
            <CustomTabPanel value={value} index={0}>
              <NewOrders />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <AllOrders />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <PastOrders />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Orders;

const NewOrders = () => {
  return <OrderTable />;
};

const AllOrders = () => {
  return <Box>All Orders</Box>;
};

const PastOrders = () => {
  return <Box>Past Orders</Box>;
};
