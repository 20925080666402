import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import location from "../../assets/location.png";
import { Button, Rating } from "@mui/material";
import parkMap from "../../assets/parkMap.png";
import { Add, MoreVert } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { viewAllParks } from "services/park";

const ParkGridCard = ({ onClick }: { onClick?: () => void }) => {
  const query = useQuery({
    queryFn: viewAllParks,
    queryKey: ["VIEW_PARKS"],
  });

  const availableParks = query.data?.data?.parks || [];

  console.log(availableParks);
  return (
    <Grid
      container
      spacing={1}
      sx={{
        border: "1px solid #E4E7EC",
        borderRadius: "10px",
        bgcolor: "#FFFFFF",
        p: "24px",
        mt: "24px",
      }}
    >
      <Grid item xs={12} lg={5}>
        <Box width="411px">
          <Typography fontSize={20} fontWeight={600}>
            Parks
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <img src={location} alt="" width="12.59px" height="15.82px" />
            <Typography>Yaba, Lagos</Typography>
            <Button variant="text" color="error">
              Edit
            </Button>
          </div>
          {availableParks.map((parks: any) => (
            <Box
              sx={{
                border: "1px solid #E4E7EC",
                borderRadius: "12px",
                p: "24px",
                my: "12px",
                position: "relative",
              }}
              onClick={onClick}
            >
              <Typography fontSize={16} fontWeight={600}>
                {parks.bus_stop} Park
              </Typography>
              <Typography
                fontSize={14}
                display="flex"
                alignItems="center"
                gap={0.5}
              >
                5.0 <Rating /> (70)
              </Typography>
              <Typography fontSize={14} color="#475367">
                {parks.address}, {parks.name}, {parks.state}
              </Typography>
              <Typography fontSize={14} color="#475367">
                <span style={{ color: "#036B26" }}>Open 24 hours </span>• 0814
                609 2019
              </Typography>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  margin: "24px",
                  borderRadius: "4px",
                  border: "1px solid #E4E7EC",
                  display: "grid",
                  placeItems: "center",
                  height: "32px",
                  width: "32px",
                }}
              >
                <MoreVert sx={{ cursor: "pointer" }} />
              </div>
            </Box>
          ))}
          {availableParks.length === 0 && (
            <Typography m={2}>No Parks Available</Typography>
          )}
          <Button
            sx={{
              bgcolor: "#000",
              borderRadius: "8px",
              p: "8px 12px",
              color: "#FFF",
            }}
            startIcon={<Add />}
          >
            Assign New
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} lg={7}>
        <img src={parkMap} alt="" height="905px" width="600px" />
      </Grid>
    </Grid>
  );
};

export default ParkGridCard;
