import { Box } from "@mui/material";
import React from "react";
import TransactionTable from "../TransactionTable";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import { AddCircleOutline } from "@mui/icons-material";
import TableSearch from "shared/TableSearch";

const Transactions = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Transaction Activities"
        onClickFirst={() => {}}
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="New Appointment"
        secondBtn
      />
      <TableSearch />
      <TransactionTable onClick={() => {}} />
    </Box>
  );
};

export default Transactions;
