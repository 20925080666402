import React, { useState } from "react";

import CloudDown from "assets/cloud-download.png";
import {
  Add,
  ArrowDropDownOutlined,
  CalendarMonthOutlined,
  NavigateBefore,
  NavigateNext,
  List,
} from "@mui/icons-material";
import OrderTable from "shared/OrderTable";
import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
import ReUseAbleTabs from "shared/ReUseAbleTabs";
import DashboardLayout from "layout";
import DashboardTopTitle from "components/DashboardTopTitle";
import ParkManagerCard from "shared/ParkManagerCard";
import TableSearch from "shared/TableSearch";
import SelectDate from "components/SelectDate";
import OrdersFlight from "./Orders(Flight)";
import OrdersRide from "./Orders(Ride)";

const tabs = [
  {
    label: "New Orders",
    path: "/orders/new-orders",
    Component: (
      <Box>
        <OrderTable />
      </Box>
    ),
  },
  {
    label: "All Orders",
    path: "/orders/all-orders",
    Component: <Box>order</Box>,
  },
  { label: "Past Orders", path: "/orders/past-orders", Component: <Box /> },
];

const Orders = () => {
  const [selected, setSelected] = useState(0);
  const onClickButton = (selectedValue: number) => {
    setSelected(selectedValue);
  };
  return (
    <DashboardLayout>
      <DashboardTopTitle
        title={"Manage Orders & Bookings"}
        firstBtn
        firstBtnIcon={<img src={CloudDown} height="20px" width="20px" alt="" />}
        firstBtnTitle="Export list"
        secondBtn
        secondBtnIcon={<Add />}
        secondBtnTitle="Invite"
      />
      <Box display="flex" alignItems="center" my="24px">
        <Button
          variant="contained"
          sx={{
            width: "254.4px",
            fontWeight: 600,
            fontSize: "14px",
            height: "36px",
            borderRadius: "8px",
            color: selected === 0 ? "white" : "#667085",
            bgcolor: selected === 0 ? "#379E66" : "white",
          }}
          onClick={() => onClickButton(0)}
        >
          Interstate Trips
        </Button>
        <Button
          sx={{
            width: "254.4px",
            fontWeight: 600,
            fontSize: "14px",
            height: "36px",
            color: selected === 1 ? "white" : "#667085",
            bgcolor: selected === 1 ? "#379E66" : "white",
            borderRadius: "8px",
          }}
          onClick={() => onClickButton(1)}
        >
          Flight
        </Button>
        <Button
          sx={{
            width: "254.4px",
            fontWeight: 600,
            fontSize: "14px",
            height: "36px",
            color: selected === 2 ? "white" : "#667085",
            bgcolor: selected === 2 ? "#379E66" : "white",
            borderRadius: "8px",
          }}
          onClick={() => onClickButton(2)}
        >
          Ride
        </Button>
      </Box>
      <Box display={selected === 0 ? "block" : "none"}>
        <Box>
          <Box bgcolor="#FFFFFF" p="24px">
            <Box px="12px">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="12px"
                >
                  <ButtonGroup>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <CalendarMonthOutlined />
                    </Button>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <List />
                    </Button>
                  </ButtonGroup>
                  <Typography
                    border="1px solid #D0D5DD"
                    borderRadius="8px"
                    p="8px 16px"
                    fontSize={14}
                    fontWeight={600}
                  >
                    Today
                  </Typography>

                  <ButtonGroup>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <NavigateBefore />
                    </Button>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <NavigateNext />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Typography fontSize={18}>July, 2023</Typography>
                <SelectDate />
              </div>

              <form
                action=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "18px",
                  marginTop: "12px",
                }}
              >
                <TextField
                  placeholder="From"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2,
                    mb: 2,
                    p: "10px 14px",
                    borderRadius: "10px",
                    width: "422.5px",
                  }}
                  type="text"
                />
                <TextField
                  placeholder="To where"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2,
                    mb: 2,
                    p: "10px 14px",
                    borderRadius: "10px",
                    width: "422.5px",
                  }}
                  type="text"
                />
                <Button
                  sx={{
                    bgcolor: "#000000",
                    color: "#FFFFFF",
                    width: "171px",
                    p: "6.75px 11.25px",
                    height: "50px",
                    borderRadius: "3.38px",
                    fontWeight: 400,
                    fontSize: "14.06px",
                  }}
                >
                  Find Bus
                </Button>
              </form>
            </Box>
          </Box>
          <ReUseAbleTabs tabs={tabs} />
        </Box>
      </Box>
      <Box display={selected === 1 ? "block" : "none"}>
        <OrdersFlight />
      </Box>
      <Box display={selected === 2 ? "block" : "none"}>
        <OrdersRide />
      </Box>
    </DashboardLayout>
  );
};

export default Orders;
