import { ArrowDownwardOutlined, CalendarMonth } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import calendarIcon from "assets/calender.svg";

const SelectDate = () => {
  const [date, setDate] = React.useState("");

  const handleChange = (e: SelectChangeEvent) => {
    setDate(e.target.value);
  };
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 160,
        border: "1px solid #E4E7EC",
        borderRadius: "10px",
        bgcolor: "#FFF",
      }}
      size="small"
    >
      <InputLabel
        id="date-select"
        sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
      >
        <img src={calendarIcon} alt="" height="16.5px" width="16.5px" /> Select
        dates
      </InputLabel>
      <Select
        labelId="selct-label"
        id="date-select"
        value={date}
        label="Date"
        onChange={handleChange}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={10}>Today</MenuItem>
        <MenuItem value={20}>Tomorrow</MenuItem>
        <MenuItem value={30}>Next</MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectDate;
