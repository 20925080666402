import { Box, Chip, Typography } from '@mui/material'
import DashboardTopTitle from 'components/DashboardTopTitle'
import DashboardLayout from 'layout'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ReUseAbleTable, { Column } from 'shared/ReUseAbleTable'

const columns: Column[] = [
    { id: "name", label: "Name", minWidth: 100 },
    {
        id: "contact", label: "Contact", minWidth: 100, format: (value: any) => (
            <Box>
                <Typography color="#344054" fontSize={14}>{value.email}</Typography>
                <Typography color="#344054" fontSize={14}>{value.phone}</Typography>
            </Box>
        )
    },
    { id: "from", label: "From", minWidth: 100 },
    { id: "to", label: "To", minWidth: 100 },
    {
        id: "eta", label: "ETA", minWidth: 100, format: (value: any) => (
            <Box>
                <Typography color="#344054" fontSize={14}>{value.date}</Typography>
                <Typography color="#344054" fontSize={14}>{value.time}</Typography>
            </Box>
        )
    },
    {
        id: "status", label: "Status", minWidth: 72, format: (value: any) => (
            <Chip label={value} size="small" sx={{ bgcolor: "#E8FFF2", color: "#379E66", fontSize: "14px" }} />
        )
    },
];

const rows = [
    {
        name: "Osim Uka",
        contact: { email: "osimuka@gmail.com", phone: "09066530997" },
        from: "Adukbakar Gamjuma Market Park",
        to: "Adukbakar Gamjuma Market Park",
        eta: { date: "7 July 2024", time: "10:00 AM" },
        status: "En Route",
        carDetails: { color: 'Black' }
    },
    {
        name: "James Woods",
        contact: { email: "jameswood@gmail.com", phone: "09012345678" },
        from: "Unity Park",
        to: "Freedom Square",
        eta: { date: "10 July 2024", time: "11:30 AM" },
        status: "Departed",
        carDetails: { color: 'Black' }
    },
    {
        name: "Alice Johnson",
        contact: { email: "alice278@gmail.com", phone: "08123456789" },
        from: "City Center Park",
        to: "Old Town Market",
        eta: { date: "15 July 2024", time: "2:00 PM" },
        status: "Scheduled",
        carDetails: { color: 'White' }
    },
    {
        name: "Robert Martin",
        contact: { email: "rmatrin@gmail.com", phone: "08098765432" },
        from: "Central Station",
        to: "Downtown Hub",
        eta: { date: "20 July 2024", time: "8:45 AM" },
        status: "Cancelled",
        carDetails: { color: 'Yellow' }
    },
    {
        name: "Linda Collins",
        contact: { email: "lindalin@gmail.com", phone: "09087654321" },
        from: "Westfield Park",
        to: "East End Terminal",
        eta: { date: "25 July 2024", time: "6:00 PM" },
        status: "Completed",
        carDetails: { color: 'Orange' }
    },
    {
        name: "Steve Carter",
        contact: { email: "stevecarter9923@gmail.com", phone: "07056789432" },
        from: "South Gate",
        to: "North Plaza",
        eta: { date: "30 July 2024", time: "12:15 PM" },
        status: "Delayed",
        carDetails: { color: 'Green' }
    },

];

export default function AssignDriver() {

    const navigate = useNavigate()

    return (
        <DashboardLayout nextSideBar subMenu goTo='/dashboard?view=ride'>
            <DashboardTopTitle title='Assign Driver' />
            <Box>
                <ReUseAbleTable
                    columns={columns}
                    rows={rows}
                    onClick={(row) => navigate("/bookings/assigndriverinfo", {
                        state: { row: row }
                    })}
                />
            </Box>
        </DashboardLayout>
    )
}
