import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Bus from "../assets/bus.svg";
import React, { useState } from "react";
import link from "../assets/link.svg";
import Call from "../assets/call.png";
import Messag from "../assets/messag.png";
import TheBus from "../assets/theBus.png";
import DashboardLayout from "../layout";
import DashboardTopTitle from "../components/DashboardTopTitle";
import { Add, CloudUploadOutlined } from "@mui/icons-material";
import OrderTable from "../shared/OrderTable";
import ReUseAbleTabs from "../shared/ReUseAbleTabs";

const TravelRegister = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout subMenu goTo="/track">
      <DashboardTopTitle
        title="Travel Register"
        onClickFirst={() => {}}
        firstBtnIcon={<CloudUploadOutlined />}
        firstBtnTitle="Export list"
        firstBtn
        secondBtn
        secondBtnIcon={<Add />}
        secondBtnTitle="Invite"
      />

      <Box border="1px solid #E4E7EC" width="100%" borderRadius="12px">
        <Box
          height="80px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="#F0F2F5"
          sx={{
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px",
            p: "16px",
          }}
        >
          <Box display="flex" alignItems="center" gap="12px">
            <div
              style={{
                height: "48px",
                width: "48px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#B5E3C4",
              }}
            >
              <img src={Bus} alt="" color="#379E66" />
            </div>
            <div>
              <Typography fontSize={14} fontWeight={600}>
                Lagos - Ilorin
              </Typography>
              <Typography fontSize={14}>ID; #78948BC-98 </Typography>
            </div>
          </Box>
          <Chip
            label="En-route"
            size="small"
            sx={{ bgcolor: "#1671D9", color: "#FFF" }}
          />
        </Box>

        <Box
          p="16px"
          borderBottom="1px solid #E4E7EC"
          height="112px"
          bgcolor="#FFF"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <Typography fontSize={14}>From</Typography>
              <Typography fontSize={14}>
                Young Legacy Park, Iyana Ipaja
              </Typography>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Typography fontSize={14}>To</Typography>
              <Typography fontSize={14}>
                Adukbakar Gamjuma Market Park
              </Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography fontSize={14}>Time of departure</Typography>
              <Typography fontSize={14}>12:34</Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Typography fontSize={14}>Time of Arrival</Typography>
              <Typography fontSize={14}>16:54 (3hrs)</Typography>
            </Grid>
            <Grid item xs={12} lg={2}>
              <AvatarGroup total={9}>
                <Avatar />
                <Avatar />
                <Avatar />
                <Avatar />
                <Avatar />
              </AvatarGroup>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
          bgcolor="#FFF"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="49px"
        >
          <Button
            variant="text"
            sx={{
              color: "#379E66",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            Track this event
          </Button>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }} mt="24px">
        <Box sx={{ width: "auto" }}>
          <Box sx={{ borderBottom: "1px solid #E4E7EC" }}>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TravelRegister;

const DriversInfo = () => {
  return (
    <Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",

          padding: "8px",
        }}
      >
        <Typography color="#475367" fontSize={16} fontWeight={600}>
          Drivers Details
        </Typography>
        <Chip size="small" label="Pending Invitation" />
      </div>
      <Divider sx={{ my: "12px" }} />
      <Box display="grid" gridTemplateColumns="55% 45%" gap={2}>
        <Box>
          <Typography fontSize={14}>Driver's Full Name</Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Avatar
              sx={{
                bgcolor: "#E8FFF2",
                color: "#000",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              M
            </Avatar>
            <Typography fontSize={16} color="#1D2739">
              Micheal Pawn
            </Typography>
          </div>
          <Divider sx={{ my: "12px" }} />
          <Box display="grid" gridTemplateColumns="1fr 1fr">
            <div>
              <Typography fontSize={14}>Email</Typography>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Typography fontSize={16}>kennethtarry@gmail.com</Typography>
                <img src={link} alt="" />
              </div>
            </div>
            <div
              style={{ borderLeft: "1px solid #E4E7EC", paddingLeft: "12px" }}
            >
              <Typography fontSize={14}>Phone Number</Typography>

              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Typography fontSize={16}>08027746758</Typography>
                <img src={link} alt="" />
              </div>
            </div>
          </Box>
          <Divider sx={{ my: "12px" }} />
          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
            <div>
              <Typography fontSize={14}>Car Type</Typography>

              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Typography fontSize={16}>Toyota Sienna</Typography>
                <img src={link} alt="" />
              </div>
            </div>
            <div
              style={{ borderLeft: "1px solid #E4E7EC", paddingLeft: "12px" }}
            >
              <Typography fontSize={14}>License Plate</Typography>

              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Typography fontSize={16}>787-FLK 878</Typography>
                <img src={link} alt="" />
              </div>
            </div>
            <div
              style={{ borderLeft: "1px solid #E4E7EC", paddingLeft: "12px" }}
            >
              <Typography fontSize={14}>Color</Typography>
              <Typography fontSize={16}>Brown</Typography>
            </div>
          </Box>
          <Box sx={{ my: "12px" }} />
          <Typography fontSize={14}>No. Of Seat</Typography>
          <Typography fontSize={14}>18</Typography>
          <Divider sx={{ my: "12px" }} />
          <Typography fontSize={14}>Notes</Typography>
          <Typography fontSize={14} color="#667185">
            Client is very straightforward about his products, he loves clean
            efficient installation and stress-free regular maintenance.
          </Typography>
        </Box>
        <Box>
          <Box p="8px" bgcolor="#F0F2F5" borderRadius="12px">
            <img
              src={TheBus}
              alt=""
              width="100%"
              style={{ objectFit: "cover", borderRadius: "12px" }}
              height="244px"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              my="12px"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Avatar />
                <Box>
                  <Typography color="#101828" fontSize={12}>
                    John Doe
                  </Typography>
                  <Typography fontSize={10}> 4.8</Typography>
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Box
                  height="36px"
                  width="36px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="#000"
                >
                  <img src={Messag} alt="" height="20px" width="20px" />
                </Box>
                <Box
                  height="36px"
                  width="36px"
                  borderRadius="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="#12B76A"
                >
                  <img src={Call} alt="" height="20px" width="20px" />
                </Box>
              </div>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography color="#667185" fontSize={16}>
                Total Remitted
              </Typography>
              <Typography fontSize={20} fontWeight={600}>
                NGN 419,090.00
              </Typography>
            </Box>
            <Divider sx={{ mt: "4px" }} />
            <Box
              height="49px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="text"
                sx={{
                  color: "#379E66",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                View Breakdown
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const Passengers = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Passengers"
        onClickFirst={() => {}}
        firstBtnIcon={<CloudUploadOutlined />}
        firstBtnTitle="Export list"
        firstBtn
        secondBtn
        secondBtnIcon={<Add />}
        secondBtnTitle="Invite"
      />
      <OrderTable />
    </Box>
  );
};

const tabs = [
  {
    label: "Drivers Info",
    path: "/travel-register/drivers-info",
    Component: <DriversInfo />,
  },
  {
    label: "Passengers",
    path: "/travel-register/passengers",
    Component: <Passengers />,
  },
];
