import { Box, List, ListItem } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface DataPoint {
  name: string;
  pv: number;
  mv: number;
}

const data: DataPoint[] = [
  {
    name: "Jan",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Feb",
    pv: 2598,
    mv: 1100,
  },
  {
    name: "Mar",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Apr",
    pv: 1008,
    mv: 1100,
  },
  {
    name: "May",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Jun",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Jul",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Aug",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Sep",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Oct",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Nov",
    pv: 1000,
    mv: 1100,
  },
  {
    name: "Dec",
    pv: 1000,
    mv: 1100,
  },
];

const InsightChart = () => {
  const chartStyle = { fontFamily: "Sora", fontSize: "12px" };
  return (
    <div>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          style={chartStyle}
        >
          <XAxis dataKey="name" tickLine={false} />
          <Tooltip />
          <CartesianGrid horizontal={true} vertical={false} />
          <Bar
            dataKey="pv"
            stackId="a"
            fill="#379E66"
            radius={[10, 10, 0, 0]}
            // background={{ fill: "#379E66" }}
            barSize={32}
          />

          <Bar
            dataKey="mv"
            stackId="a"
            fill="#E4E7EC"
            radius={[10, 10, 0, 0]}
            barSize={32}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InsightChart;
