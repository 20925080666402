import DashboardLayout from "../../layout";
import Box from "@mui/material/Box";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import {
  Add,
  CloudDownloadOutlined,
  MoreVert,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import redChart from "assets/redChart.svg";
import persons from "assets/persons.png";
import VendorBreakdown from "./VendorBreakdown";
import TheChart from "./TheChart";
import SessionByCountry from "./SessionByCountry";
import VendorMonitored from "./VendorMonitored";

const Analytics = () => {
  return (
    <DashboardLayout subMenu goTo="/dashboard">
      <Box>
        <DashboardTopTitle
          title="Analytics"
          subtitle="Your current sales summary and activity."
          onClickFirst={() => {}}
          firstBtn
          firstBtnIcon={<CloudDownloadOutlined />}
          firstBtnTitle="Export report"
          secondBtn
          secondBtnIcon={<Add />}
          secondBtnTitle="Invite"
        />
      </Box>

      <Grid container spacing={2} mt="12px">
        <Grid item xs={12} lg={3}>
          <AnayticsCards title="Total customers" value={2420} trendingUp />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AnayticsCards title="Affliates" value={1210} trendingDown />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AnayticsCards title="Park Managers" value={1210} trendingDown />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AnayticsCards title="Park Managers" value={1210} trendingDown />
        </Grid>
      </Grid>
      <Grid container spacing={2} my="12px">
        <Grid item xs={12} lg={8}>
          <TheChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <SessionByCountry />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          <VendorBreakdown />
        </Grid>
        <Grid item xs={12} lg={3}>
          <VendorMonitored value={240} />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Analytics;

interface AnalyticsCardsProp {
  title: string;
  value: number;
  trendingUp?: boolean;
  trendingDown?: boolean;
}
const AnayticsCards = ({
  title,
  value,
  trendingUp,
  trendingDown,
}: AnalyticsCardsProp) => {
  return (
    <Box
      width="100%"
      border="1px solid #E4E7EC"
      borderRadius="12px"
      bgcolor="#FFFFFF"
      height="166px"
      p="24px"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Box
            height={48}
            width={48}
            borderRadius="10px"
            border="1px solid #E4E7EC"
            display="grid"
            sx={{ placeContent: "center" }}
          >
            <img
              src={persons}
              alt=""
              style={{ height: "24px", width: "24px" }}
            />
          </Box>
          <Typography
            textAlign="left"
            color="#101828"
            fontSize={16}
            fontWeight={600}
          >
            {title}
          </Typography>
        </div>
        <MoreVert />
      </div>
      <Box height="12px" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          textAlign="left"
          color="#101828"
          fontSize={30}
          fontWeight={600}
        >
          {value}
        </Typography>
        {trendingUp && (
          <Box display="flex" alignItems="center">
            <TrendingUp sx={{ color: "#067647" }} />
            <Typography fontSize={14} sx={{ color: "#067647" }}>
              40%
            </Typography>
          </Box>
        )}
        {trendingDown && (
          <Box display="flex" alignItems="center">
            <TrendingDown sx={{ color: "#B42318" }} />
            <Typography fontSize={14} sx={{ color: "#B42318" }}>
              10%
            </Typography>
          </Box>
        )}

        <img
          src={redChart}
          alt=""
          width="100%"
          height="auto"
          style={{ objectFit: "cover", marginLeft: "4px" }}
        />
      </div>
    </Box>
  );
};
