import { Box, Chip, Typography } from '@mui/material';
import DashboardLayout from 'layout';
import { useNavigate } from 'react-router-dom';
import ReUseAbleTable, { Column } from 'shared/ReUseAbleTable';
import { getStatusStyle } from 'utils/misc';

const AirlineDetails = () => {
  const columns: Column[] = [
    { id: 'flightNumber', label: 'Flight Number' },
    { id: 'airline', label: 'Airline Name' },
    { id: 'from', label: 'From' },
    { id: 'to', label: 'To' },
    { id: 'eta', label: 'ETA' },
    { id: 'status', label: 'Status' },
  ];

  const rows = [
    {
      flightNumber: 'B12366',
      airline: 'Arik Air',
      from: 'General Tunde Idiagbon International Airport, Ilorin',
      to: 'Nnamdi  Azikwe International Airport, Abuja',
      eta: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2024</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
      status: <Chip label={'Completed'} sx={getStatusStyle('Completed')} />,
    },
    {
      flightNumber: 'B12366',
      airline: 'Arik Air',
      from: 'General Tunde Idiagbon International Airport, Ilorin',
      to: 'Nnamdi  Azikwe International Airport, Abuja',
      eta: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2024</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
      status: <Chip label={'Completed'} sx={getStatusStyle('Completed')} />,
    },
    {
      flightNumber: 'B12366',
      airline: 'Arik Air',
      from: 'General Tunde Idiagbon International Airport, Ilorin',
      to: 'Nnamdi  Azikwe International Airport, Abuja',
      eta: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2024</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
      status: <Chip label={'Completed'} sx={getStatusStyle('Completed')} />,
    },
    {
      flightNumber: 'B12366',
      airline: 'Arik Air',
      from: 'General Tunde Idiagbon International Airport, Ilorin',
      to: 'Nnamdi  Azikwe International Airport, Abuja',
      eta: (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>7 Jul, 2024</Typography>
          <Typography sx={{ fontSize: '12px' }}>10:00 AM</Typography>
        </Box>
      ),
      status: <Chip label={'Completed'} sx={getStatusStyle('Completed')} />,
    },
  ];

  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <Box mb='24px' display='flex' gap='10px'>
        <div>
          <Typography color='#101828' fontSize='24px' fontWeight={600}>
            Manage Bookings
          </Typography>
        </div>
      </Box>
      <ReUseAbleTable
        columns={columns}
        rows={rows}
        onClick={() => navigate('/bookings/flightorderdetails')}
      />
    </DashboardLayout>
  );
};

export default AirlineDetails;
