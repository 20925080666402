import { Avatar, Box, Divider, Rating, Typography } from '@mui/material';
import React from 'react';
import ReUseAbleTextField from '../../../shared/ReUseAbleTextField';

const Information = () => {
  return (
    <Box>
      <Box height='120px' display='grid' gridTemplateColumns='1fr 1fr'>
        <div style={{ display: 'flex', alignItems: 'center', gap: '41px' }}>
          <Avatar sx={{ height: '120px', width: '120px' }} />
          <Box>
            <Typography fontSize={16} fontWeight={600} color='#344054'>
              John Doe
            </Typography>
          </Box>
        </div>
      </Box>
      <Divider sx={{ my: '24px' }} />
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <div>
          <Typography fontSize={16} fontWeight={600}>
            Profile details
          </Typography>
          <Typography fontSize={14}>
            Update your personal details here.
          </Typography>
        </div>
        <Box width='707px'>
          <form autoComplete='off'>
            <ReUseAbleTextField
              title='Gender'
              placeholder='Gender'
              type='text'
            />
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={2}
            >
              <ReUseAbleTextField
                title='Email'
                placeholder='admin@move9ja.com'
                type='text'
              />
              <ReUseAbleTextField
                title='Nationality'
                placeholder='Nigerian'
                type='text'
              />
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              gap={2}
            >
              <ReUseAbleTextField
                title='Date of birth'
                placeholder='June 12, 2024'
                type='text'
              />
              <ReUseAbleTextField
                title='Phone Number'
                placeholder='+234 814 3577 803'
                type='text'
              />
            </Box>
          </form>
        </Box>
      </Box>
      <Divider sx={{ my: '24px' }} />
    </Box>
  );
};

export default Information;
