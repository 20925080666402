import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import move9jaLogo from "../assets/logo.svg";
import backGroundImage from "../assets/loginImage.png";
import { ArrowBack, Check } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AlertMessage from "../components/AlertMessage";
import { useForm, Controller, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { IUser, signUp } from "services/auth";
import { convertKeysToSnakeCase } from "utils/misc";

// Define the validation schema using Yup
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  username: yup.string().required("Username is required"),
  dob: yup.date().required("Date of birth is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  role: yup.string().required("Role is required"),
  phone: yup.string().required("Phone number is required"),
});

const SignUp: React.FC = () => {
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mutation = useMutation({
    mutationFn: async (data: IUser) => {
      // Simulate a network request
      return await signUp(data);
    },
    onSuccess: (data) => {
      console.log("on Success", data);
      navigate("/");
    },
  });

  const onSubmit = (data: FieldValues) => {
    const date = new Date(data?.dob);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const datVal = {
      ...(data as Omit<IUser, "dob">),
      dob: formattedDate,
    };
    console.log("On submit data", datVal);
    mutation.mutate(convertKeysToSnakeCase(datVal));
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 32px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
      </Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: "grid",
            placeItems: "end",
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' flexDirection='column'>
          <Box width='510px' p='0px 24px' mt='70px'>
            <Box display='flex' alignItems='center' gap={1} mb='12px'>
              <ArrowBack />
              <Typography fontWeight={600}>Login</Typography>
            </Box>
            <Typography mb='42px'>Welcome Admin</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email
                </InputLabel>
                <Controller
                  name='email'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter a registered email'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  First Name
                </InputLabel>
                <Controller
                  name='firstName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your first name'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Last Name
                </InputLabel>
                <Controller
                  name='lastName'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your last name'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Username
                </InputLabel>
                <Controller
                  name='username'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your username'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.username}
                      helperText={errors.username?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Date of Birth
                </InputLabel>
                <Controller
                  name='dob'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type='date'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.dob}
                      helperText={errors.dob?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Password
                </InputLabel>
                <Controller
                  name='password'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type='password'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Role
                </InputLabel>
                <Controller
                  name='role'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your role'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.role}
                      helperText={errors.role?.message}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Phone
                </InputLabel>
                <Controller
                  name='phone'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your phone number'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                    />
                  )}
                />
              </FormControl>
              <Button
                disabled={!!mutation.isPending}
                type='submit'
                variant='contained'
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: "8px",
                  p: "12px 24px",
                }}
              >
                {mutation.isPending ? "Loading.." : "Send magic link"}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      {sent && (
        <AlertMessage
          title='Login link has been sent to admin@move9ja.com'
          icon={<Check />}
          bgColor='#E8FFF2'
          textColor='#379E66'
        />
      )}
    </Box>
  );
};

export default SignUp;
