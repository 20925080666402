import { Box } from '@mui/material';
import React from 'react';
import ReUseAbleTabs from 'shared/ReUseAbleTabs';
import Information from './SubCards/Information';
import Transactions from './SubCards/Transactions';
import SubTravels from './SubCards/SubTravels';
import Insights from './SubCards/Insights';
import DashboardLayout from 'layout';
import DashboardTopTitle from 'components/DashboardTopTitle';
import { CloseRounded, Download } from '@mui/icons-material';

const tabs = [
  {
    label: 'Information',
    path: '/users/sub/',
    Component: <Information />,
  },
  { label: 'Insights', path: '/users/sub/insights', Component: <Insights /> },

  {
    label: 'Transactions',
    path: '/users/sub/transactions',
    Component: <Transactions />,
  },
  {
    label: 'Travels',
    path: '/users/sub/travels',
    Component: <SubTravels />,
  },
];

const UsersSub = () => {
  return (
    <DashboardLayout nextSideBar>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: 'auto' }}>
          <Box>
            <DashboardTopTitle
              title='User Details'
              subtitle='Showing data over the last 30 days.'
              onClickFirst={() => {}}
              firstBtnIcon={<Download />}
              firstBtnTitle='Export CSV'
              secondBtnIcon={<CloseRounded />}
              // secondBtnTitle='Blacklist User'
              firstBtn
              // secondBtn
              secondBgColor='#DA4733'
            />
          </Box>
          <Box>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default UsersSub;
