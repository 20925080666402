import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import { Typography, Box, styled } from "@mui/material";

const TimelineOppositeContentStyled = styled(TimelineOppositeContent)(
  ({ theme }) => ({
    flex: 0.2,
  })
);

const LocationTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: "bold",
  fontSize: "12px",
}));

const TimeTypography = styled(Typography)(({ theme }) => ({
  color: "#4caf50",
  fontWeight: "bold",
}));

const DescriptionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const SquareDot = styled(Box)(({ theme }) => ({
  width: "12px",
  height: "12px",
  backgroundColor: "#D9D9D9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export default function CustomTimeline() {
  return (
    <Timeline>
      <TimelineItem>
        <TimelineOppositeContentStyled>
          <LocationTypography>Lagos</LocationTypography>
        </TimelineOppositeContentStyled>
        <TimelineSeparator sx={{ mt: 2 }}>
          <SquareDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <TimeTypography>6:45am</TimeTypography>
          <DescriptionTypography sx={{ fontSize: "12px" }}>
            Young Legacy Park, <br /> Iyana-Ipaja
          </DescriptionTypography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContentStyled>
          <LocationTypography>Ilorin</LocationTypography>
        </TimelineOppositeContentStyled>
        <TimelineSeparator>
          <SquareDot />
        </TimelineSeparator>
        <TimelineContent>
          <TimeTypography>12:03pm</TimeTypography>
          <DescriptionTypography sx={{ fontSize: "12px" }}>
            Abukbakar Gamjuma
            <br />
            Market Park
          </DescriptionTypography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
