import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import move9jaLogo from "assets/logo.svg";
import backGroundImage from "assets/loginImage.png";
import { useMutation } from "@tanstack/react-query";
import { createPark, IPark } from "services/park";
import { convertKeysToSnakeCase } from "utils/misc";

import toast from "react-hot-toast";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
});

const CreateCustomers = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    mutation.mutate(convertKeysToSnakeCase(data));
    console.log(data);
  };

  const mutation = useMutation({
    mutationFn: async (data: IPark) => {
      // Simulate a network request
      return await createPark(data);
    },
    onSuccess: (data) => {
      console.log("on Success", data);
      toast.success("Park created succesfully!");
      reset({});
      //   navigate("/");
    },
  });

  return (
    <Box height="100vh">
      <Box
        height="72px"
        p="0px 32px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        border="1px solid #E4E7EC"
      >
        <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
      </Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: "grid",
            placeItems: "end",
          }}
        >
          <Typography
            color="#fff"
            fontSize="32px"
            fontWeight={600}
            height="92px"
            width="324px"
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box width="510px" p="0px 24px" mt="70px">
            <Typography mb="42px">Create Customer</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Name
                </InputLabel>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter your name"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.name}
                      helperText={errors.name ? errors.name.message : ""}
                    />
                  )}
                />
              </FormControl>

              <FormControl variant="standard" fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email
                </InputLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter your email"
                      variant="standard"
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  )}
                />
              </FormControl>
              <Button
                disabled={!!mutation.isPending}
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: "8px",
                  p: "12px 24px",
                }}
              >
                {mutation.isPending ? "Loading.." : "Submit"}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCustomers;
