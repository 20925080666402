import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Typography,
  Button,
  Rating,
} from "@mui/material";
import parkMap from "../../../assets/parkMap.png";
import { Add, MapOutlined } from "@mui/icons-material";

const ParkManager = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5}>
        <Box width="411px">
          <Typography fontSize={20} fontWeight={600}>
            Parks
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <MapOutlined />
            <Typography>Yaba, Lagos</Typography>
            <Button variant="text" color="error">
              Edit
            </Button>
          </div>
          <Box
            sx={{
              border: "1px solid #E4E7EC",
              borderRadius: "12px",
              p: "24px",
              my: "12px",
            }}
          >
            <Typography fontSize={16} fontWeight={600}>
              Cottage Medicare Park
            </Typography>
            <Typography fontSize={14}>
              5.0 <Rating /> (70)
            </Typography>
            <Typography fontSize={14} color="#475367">
              18 Iwaya Rd, Yaba 101245, Lagos
            </Typography>
            <Typography fontSize={14} color="#475367">
              <span style={{ color: "#036B26" }}>Open 24 hours </span>• 0814 609
              2019
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #E4E7EC",
              borderRadius: "12px",
              p: "24px",
              my: "12px",
            }}
          >
            <Typography fontSize={16} fontWeight={600}>
              Cottage Medicare Park
            </Typography>
            <Typography fontSize={14}>
              5.0 <Rating /> (70)
            </Typography>
            <Typography fontSize={14} color="#475367">
              18 Iwaya Rd, Yaba 101245, Lagos
            </Typography>
            <Typography fontSize={14} color="#475367">
              <span style={{ color: "#036B26" }}>Open 24 hours </span>• 0814 609
              2019
            </Typography>
          </Box>
          <Button
            sx={{
              bgcolor: "#000",
              borderRadius: "8px",
              p: "8px 12px",
              color: "#FFF",
            }}
            startIcon={<Add />}
          >
            Assign New
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} lg={7}>
        <img src={parkMap} alt="" height="905px" width="616px" />
      </Grid>
    </Grid>
  );
};

export default ParkManager;
