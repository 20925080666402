import React, { useState } from "react";
import DashboardLayout from "../../layout";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import { AddCircleOutline, Download } from "@mui/icons-material";
import CustomTable from "../../components/CustomTable";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout nextSideBar>
      <Box>
        <DashboardTopTitle
          title="Users"
          subtitle="Showing data over the last 30 days."
          onClickFirst={() => {}}
          firstBtnIcon={<Download />}
          firstBtnTitle="Export CSV"
          secondBtnIcon={<AddCircleOutline />}
          secondBtnTitle="Add Employee"
          firstBtn
          secondBtn
          onClickSecond={() => navigate("/create-users")}
        />
      </Box>
      <CustomTable onClick={() => {
        navigate('/users/sub/')
        }}
      />
      
    </DashboardLayout>
  );
};

export default Users;
