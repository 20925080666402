import React, { useState } from "react";
import DashboardLayout from "../../layout";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import { AddCircleOutline, Download } from "@mui/icons-material";
import CustomTable from "../../components/CustomTable";
import { Container, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Information from "./SubCards/Information";
import Insights from "./SubCards/Insights";

import Transactions from "./SubCards/Transactions";
import Order from "./SubCards/Orders";
import Referrals from "./SubCards/Referrals";
import ReUseAbleTabs from "../../shared/ReUseAbleTabs";
import { useNavigate } from "react-router-dom";



const Affliates = () => {
 
 const nav = useNavigate()
  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title="Affliates"
          subtitle="Showing data over the last 30 days."
          onClickFirst={() => {}}
          firstBtnIcon={<Download />}
          firstBtnTitle="Export CSV"
          secondBtnIcon={<AddCircleOutline />}
          secondBtnTitle="Blacklist User"
          firstBtn
          secondBtn
          secondBgColor="#DA4733"
        />
      </Box>

      <CustomTable onClick={() => nav('/affliates/sub/')} />
     
    </DashboardLayout>
  );
};

export default Affliates;
