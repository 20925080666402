import { Box, Button, ButtonGroup, TextField, Typography } from '@mui/material';
import DashboardLayout from '../../layout';
import { useEffect, useState } from 'react';
import {
  ArrowDropDownOutlined,
  CalendarMonthOutlined,
  List,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material';
import SelectDate from '../../components/SelectDate';
import { CustomIcon } from '../../components/DashboardTableEvents';
import AppTable from 'pages/Dashboard/Table';
import DashboardFlight from './Dashboard(Flight)';
import DashboardRide from './Dashboard(Ride)';
import { useLocation, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const goTo = useNavigate();
  const location = useLocation();
  const urlParameter = new URLSearchParams(location.search);
  const initialStateView = urlParameter.get('view') || 'interstate';

  const [selected, setSelected] = useState(
    initialStateView === 'interstate'
      ? 0
      : initialStateView === 'flight'
      ? 1
      : 2
  );
  const [nextSideBar, setNextSideBar] = useState(selected !== 0);

  useEffect(() => {
    const savedView = localStorage.getItem('dashboardView');
    if (savedView) {
      setSelected(parseInt(savedView));
      setNextSideBar(parseInt(savedView) !== 0);
    }
  }, []);

  const onClickButton = (selectedValue: number, view: string) => {
    setSelected(selectedValue);
    setNextSideBar(selectedValue !== 0);
    localStorage.setItem('dashboardView', selectedValue.toString());
    goTo(`/dashboard?view=${view}`);
  };

  const headers = [
    { label: 'Travel ID', key: 'travelID', width: '137px' },
    { label: 'From', key: 'from', width: '242px' },
    { label: 'To', key: 'to', width: '188px' },
    { label: 'Park Manager', key: 'parkManagerName', width: '166px' },
    { label: 'Status', key: 'status', width: '166px' },
    { label: 'ETA', key: 'eta', width: '166px' },
  ];

  const data = [
    {
      travelID: 'LAGJ-9080',
      from: 'Young Legacy Park, Iyana',
      to: 'Adukbakar Gamjuma Market Park',
      parkManagerName: 'John Doe',
      parkManagerNumb: '09066530997',
      status: 'En Route',
      eta: '7 July 2024, 10:00 AM',
    },
    // Add more rows as needed
  ];

  return (
    <DashboardLayout nextSideBar={nextSideBar}>
      <Box sx={{ width: '100%' }}>
        <Box mb='24px' display='flex' gap='10px'>
          <div>
            <Typography color='#101828' fontSize='24px' fontWeight={600}>
              Welcome Admin
            </Typography>
          </div>
        </Box>
        <Box display='flex' alignItems='center' my='24px'>
          <Button
            variant='contained'
            sx={{
              width: '254.4px',
              fontWeight: 600,
              fontSize: '14px',
              height: '36px',
              borderRadius: '8px',
              color: selected === 0 ? 'white' : '#667085',
              bgcolor: selected === 0 ? '#379E66' : 'white',
            }}
            onClick={() => onClickButton(0, 'interstate')}
          >
            Interstate Trips
          </Button>
          <Button
            sx={{
              width: '254.4px',
              fontWeight: 600,
              fontSize: '14px',
              height: '36px',
              color: selected === 1 ? 'white' : '#667085',
              bgcolor: selected === 1 ? '#379E66' : 'white',
              borderRadius: '8px',
            }}
            variant='contained'
            onClick={() => onClickButton(1, 'flight')}
          >
            Flight
          </Button>
          <Button
            sx={{
              width: '254.4px',
              fontWeight: 600,
              fontSize: '14px',
              height: '36px',
              color: selected === 2 ? 'white' : '#667085',
              bgcolor: selected === 2 ? '#379E66' : 'white',
              borderRadius: '8px',
            }}
            variant='contained'
            onClick={() => onClickButton(2, 'ride')}
          >
            Ride
          </Button>
        </Box>
        <Box display={selected === 0 ? 'block' : 'none'}>
          <Box bgcolor='#FFFFFF' p='24px'>
            <Typography
              color='#101828'
              fontSize={24}
              fontWeight={600}
              mb='24px'
            >
              Quick Tracking
            </Typography>
            <Box px='12px'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '12px',
                }}
              >
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='space-between'
                  gap='12px'
                >
                  <ButtonGroup>
                    <Button
                      sx={{
                        border: '1px solid #D0D5DD',
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                      }}
                    >
                      <CalendarMonthOutlined />
                    </Button>
                    <Button
                      sx={{
                        border: '1px solid #D0D5DD',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                      }}
                    >
                      <List />
                    </Button>
                  </ButtonGroup>
                  <Typography
                    border='1px solid #D0D5DD'
                    borderRadius='8px'
                    p='8px 16px'
                    fontSize={14}
                    fontWeight={600}
                  >
                    Today
                  </Typography>

                  <ButtonGroup>
                    <Button
                      sx={{
                        border: '1px solid #D0D5DD',
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                      }}
                    >
                      <NavigateBefore />
                    </Button>
                    <Button
                      sx={{
                        border: '1px solid #D0D5DD',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                      }}
                    >
                      <NavigateNext />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Typography fontSize={18}>July, 2023</Typography>
                <SelectDate />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  fontSize={14}
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    gap: 4,
                  }}
                >
                  Find by{' '}
                  <span
                    style={{
                      textDecoration: 'underline',
                      color: '#379E66',
                      fontSize: 14,
                      display: 'flex',
                      alignContent: 'center',
                    }}
                  >
                    Event <ArrowDropDownOutlined />
                  </span>
                </Typography>
              </div>
              <form
                action=''
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '18px',
                  marginTop: '12px',
                }}
              >
                <TextField
                  placeholder='From'
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2,
                    mb: 2,
                    p: '10px 14px',
                    borderRadius: '10px',
                    width: '422.5px',
                  }}
                  type='text'
                />
                <TextField
                  placeholder='To where'
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    bgcolor: '#F9F9F9',
                    mt: 2,
                    mb: 2,
                    p: '10px 14px',
                    borderRadius: '10px',
                    width: '422.5px',
                  }}
                  type='text'
                />
                <Button
                  sx={{
                    bgcolor: '#000000',
                    color: '#FFFFFF',
                    width: '171px',
                    p: '6.75px 11.25px',
                    height: '50px',
                    borderRadius: '3.38px',
                    fontWeight: 400,
                    fontSize: '14.06px',
                  }}
                >
                  Find Bus
                </Button>
              </form>
            </Box>
          </Box>
          <Box height='24px' />
          <AppTable headers={headers} data={data} />
        </Box>
        <Box display={selected === 1 ? 'block' : 'none'}>
          <DashboardFlight />
        </Box>
        <Box display={selected === 2 ? 'block' : 'none'}>
          <DashboardRide />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
