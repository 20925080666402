import { Avatar, Box, Checkbox, Typography } from '@mui/material';
import DashboardLayout from '../../layout';

import AppTable from '../Dashboard/Table';

const Transactions = () => {
  const header = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Transaction ID',
      key: 'transactionId',
    },
    {
      label: 'Amount',
      key: 'amount',
    },
    {
      label: 'Payment Method',
      key: 'method',
    },
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Status',
      key: 'status',
    },
  ];

  const data = [
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
    {
      name: (
        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            <Checkbox />
            <Avatar>E</Avatar>
          </Box>
          <Box sx={{ fontSize: '12px !important', ml: 1 }}>
            <Typography sx={{ fontSize: '12px !important' }}>
              Emery Torff
            </Typography>
            <Typography sx={{ fontSize: '12px !important' }}>
              thekdfisher@email.com
            </Typography>
          </Box>
        </Box>
      ),
      transactionId: '#6544GF8',
      amount: 'NGN 1,000000',
      method: 'Card Payment',
      date: 'Apr 12, 2023 | 09:32AM',
      status: 'Success',
    },
  ];
  return (
    <DashboardLayout nextSideBar>
      <Box sx={{ width: '100%' }}>
        <Box mb='24px' display='flex' gap='10px'>
          <div>
            <Typography color='#101828' fontSize='24px' fontWeight={600}>
              Transactions
            </Typography>
          </div>
        </Box>
        {/* <TableSearch /> */}
        <Box>
          <AppTable headers={header} data={data} />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Transactions;
