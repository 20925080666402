import { Box, Button, Typography } from "@mui/material";
import React from "react";

const ParkManagerCard = ({ width = "755px" }: { width?: "auto" | "755px" }) => {
  return (
    <Box
      p="24px"
      borderRadius="12px"
      bgcolor="#FFFFFF"
      width={width}
      height="199px"
      border="1px solid #E4E7EC"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography fontWeight={600} fontSize={18}>
          New park managers
        </Typography>
        <Button
          variant="outlined"
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            borderRadius: "8px",
          }}
        >
          View all
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflowX: "scroll",
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <CustomInfo />
        <CustomInfo />
        <CustomInfo />
        <CustomInfo />
        <CustomInfo />
        <CustomInfo />
      </div>
    </Box>
  );
};

export default ParkManagerCard;

const CustomInfo = () => {
  return (
    <Box width="100%">
      <Box
        width="307px"
        height="91px"
        borderRadius="5px"
        borderLeft="7px solid green"
        paddingLeft="12px"
      >
        <Typography fontSize={14} color="#475367">
          11.30 - 12.30 (30min)
        </Typography>
        <Typography fontSize={16} color="#101928">
          John Doe
        </Typography>
        <Typography fontSize={14} color="#475367">
          081067683789
        </Typography>
        <Typography fontSize={14} color="#475367">
          Ikoyi Park
        </Typography>
      </Box>
    </Box>
  );
};
