import { Routes as BrowserRoutes, Route } from 'react-router-dom';
import LoginScreen from '../pages/LoginScreen';
import { Fragment } from 'react/jsx-runtime';
import AuthGuard from './AuthGuard';
import Dashboard from '../pages/Dashboard/Dashboard';
import Messages from '../pages/Messages';
import Parks from '../pages/Parks/Parks';
import Customers from '../pages/Customers/Customers';
import Wallet from '../pages/Wallet/Wallet';
import ParkManagers from '../pages/ParkManagers/ParkManagers';
import Affliates from '../pages/Affliates/Affliates';
import Travels from '../pages/Travels';
import Settings from '../pages/Settings/Settings';
import CreateAd from '../pages/CreateAd';
import Promo from '../pages/Promo';
import Analytics from '../pages/Analytics/Analytics';
import Tracks from '../pages/Track/Tracks';
import AvailableRoutes from '../pages/AvailableRoutes';
import SignUp from '../pages/Signup';
import CreatePark from 'pages/Parks/CreatePark';
import CreateParkManager from 'pages/ParkManagers/CreateParkManager';
import TravelRegister from '../pages/TravelRegister';
import OrderDetails from '../pages/OrderDetails';
import Events from '../pages/Events';
import Orders from '../pages/Order/Orders(Trip)';
import CreateCustomers from 'pages/Customers/CreateCustomers';
import Transactions from 'pages/Transactions/Transactions';
import BookingOrderDetails from 'pages/Bookings/OrderDetails';
import Users from 'pages/Users/Users';
import UsersSub from 'pages/Users/Users(Sub)';
import CustomersSub from 'pages/Customers/Customer(Sub)';
import ParkManagerSub from 'pages/ParkManagers/ParkManager(Sub)';
import AffliatesSub from 'pages/Affliates/Affliiates(Sub)';
import AssignDriver from 'pages/Bookings/AssignDriver';
import AssignDriverPot from 'pages/Bookings/AssignDriverPot';
import PriceEstimator from 'pages/Bookings/PriceEstimator';
import BookingFlight from 'pages/Bookings/Booking(Flight)';
import BookingRide from 'pages/Bookings/Booking(Ride)';
import AirlineDetails from 'pages/Bookings/AirlineDetails';

interface RouteConfig {
  path: string;
  element: JSX.Element;
  hasAuth?: boolean;
}
const ROUTES: RouteConfig[] = [
  {
    path: '/',
    element: <LoginScreen />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
  },

  {
    path: '/wallet',
    element: <Wallet />,
  },
  {
    path: '/customers',
    element: <Customers />,
  },
  {
    path: '/customers/sub/',
    element: <CustomersSub />,
  },
  {
    path: '/customers/sub/insights',
    element: <CustomersSub />,
  },
  {
    path: '/customers/sub/medical-info',
    element: <CustomersSub />,
  },
  {
    path: '/customers/sub/travels',
    element: <CustomersSub />,
  },
  {
    path: '/customers/sub/transactions',
    element: <CustomersSub />,
  },
  {
    path: '/create-customers',
    element: <CreateCustomers />,
  },
  {
    path: '/park-managers',
    element: <ParkManagers />,
  },
  {
    path: '/park-managers/sub/events',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/insight',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/orders',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/park-assigned',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/transactions',
    element: <ParkManagerSub />,
  },
  {
    path: '/park-managers/sub/travels',
    element: <ParkManagerSub />,
  },
  {
    path: '/affliates',
    element: <Affliates />,
  },
  {
    path: '/affliates/sub/',
    element: <AffliatesSub />,
  },
  {
    path: '/affliates/sub/insights',
    element: <AffliatesSub />,
  },
  {
    path: '/affliates/sub/transactions',
    element: <AffliatesSub />,
  },
  {
    path: '/affliates/sub/orders',
    element: <AffliatesSub />,
  },
  {
    path: '/affliates/sub/referrals',
    element: <AffliatesSub />,
  },
  {
    path: '/parks',
    element: <Parks />,
  },
  {
    path: '/parks/information',
    element: <Parks />,
  },
  {
    path: '/parks/insights',
    element: <Parks />,
  },
  {
    path: '/parks/media',
    element: <Parks />,
  },
  {
    path: '/parks/park-manager',
    element: <Parks />,
  },
  {
    path: '/parks/transactions',
    element: <Parks />,
  },
  {
    path: '/parks/travels',
    element: <Parks />,
  },
  {
    path: '/messages',
    element: <Messages />,
  },

  {
    path: '/travels',
    element: <Travels />,
  },
  {
    path: '/travels/past-travels',
    element: <Travels />,
  },
  {
    path: '/travels/new-travels',
    element: <Travels />,
  },
  {
    path: '/travels/all-travels',
    element: <Travels />,
  },

  {
    path: '/promo',
    element: <Promo />,
  },
  {
    path: '/create-ad',
    element: <CreateAd />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
  {
    path: '/settings/profile',
    element: <Settings />,
  },
  {
    path: '/settings/payout',
    element: <Settings />,
  },
  {
    path: '/settings/admins',
    element: <Settings />,
  },
  {
    path: '/routes',
    element: <AvailableRoutes />,
  },
  {
    path: '/users',
    element: <Users />,
  },
  {
    path: '/users/sub/',
    element: <UsersSub />,
  },
  {
    path: '/users/sub/insights',
    element: <UsersSub />,
  },
  {
    path: '/users/sub/travels',
    element: <UsersSub />,
  },
  {
    path: '/users/sub/transactions',
    element: <UsersSub />,
  },
  {
    path: '/bookings/flight',
    element: <BookingFlight />,
  },
  {
    path: '/bookings/flight/:id',
    element: <AirlineDetails />,
  },
  {
    path: '/bookings/ride',
    element: <BookingRide />,
  },
  {
    path: '/bookings/rideorderdetails',
    element: <BookingOrderDetails />,
  },
  {
    path: '/bookings/flightorderdetails',
    element: <BookingOrderDetails />,
  },
  {
    path: '/bookings/assigndriver',
    element: <AssignDriver />,
  },
  {
    path: '/bookings/assigndriverinfo',
    element: <AssignDriverPot />,
  },
  {
    path: '/bookings/priceestimator',
    element: <PriceEstimator />,
  },
  {
    path: '/transactions',
    element: <Transactions />,
  },
  {
    path: '/travel-register',
    element: <TravelRegister />,
  },
  {
    path: '/travel-register/drivers-info',
    element: <TravelRegister />,
  },
  {
    path: '/travel-register/passengers',
    element: <TravelRegister />,
  },
  {
    path: '/track',
    element: <Tracks />,
  },
  {
    path: '/order-details',
    element: <OrderDetails />,
  },
  {
    path: '/analytics',
    element: <Analytics />,
  },
  {
    path: '/create-park',
    element: <CreatePark />,
  },
  {
    path: '/create-park-manager',
    element: <CreateParkManager />,
  },
  {
    path: '/events',
    element: <Events />,
  },

  {
    path: '/orders',
    element: <Orders />,
  },
  {
    path: '/orders/new-orders',
    element: <Orders />,
  },
  {
    path: '/orders/all-orders',
    element: <Orders />,
  },
  {
    path: '/orders/past-orders',
    element: <Orders />,
  },
  {
    path: '*',
    element: <h4>Not found</h4>,
  },
];
const AppRoute = () => {
  return (
    <>
      <BrowserRoutes>
        {ROUTES.map((route) => {
          const { hasAuth } = route;
          const Parent = hasAuth ? AuthGuard : Fragment;
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<Parent>{route.element}</Parent>}
            />
          );
        })}
      </BrowserRoutes>
    </>
  );
};

export default AppRoute;
