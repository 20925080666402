import Box from "@mui/material/Box";

import SelectDate from "../components/SelectDate";
import { Button, TextField } from "@mui/material";
import {  Search } from "@mui/icons-material";
import filter from "../assets/filter.png";

type TableSearchProps = {
  noBgColor?: boolean;
  p?: string;
  searchPlaceHolder?: string;
  searchValue?: string
  setSearchValue?: React.Dispatch<React.SetStateAction<string>>;
}

const TableSearch = ({ noBgColor, p = '16px', searchPlaceHolder = 'Search here...',searchValue ,setSearchValue}:
  TableSearchProps) => {
  
  return (
    <Box
      height='68px'
      bgcolor={!noBgColor ? "#FFFFFF" : "transparent"}
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      p={p}
    >
      <div  style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <TextField
          variant='standard'
          sx={{
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            width: "291px",
            p: "5px 12px",
          }}
          placeholder={searchPlaceHolder}
          value={searchValue}
          InputProps={{
            startAdornment: <Search />,
            disableUnderline: true,
          }}
          onChange={(e) => setSearchValue!(e.target.value)}
        />
        <Button
          sx={{
            color: "#344054",
            fontSize: "14px",
            fontWeight: 600,
            border: "1px solid #E4E7EC",
            borderRadius: "8px",
            p: "8px 12px",
            bgcolor: "#FFF",
          }}
          startIcon={<img src={filter} alt='' height='20px' width='20px' />}
          variant='outlined'
        >
          Filter
        </Button>
      </div>
      <SelectDate />
    </Box>
  );
};

export default TableSearch;
