import { Box } from '@mui/material'
import React from 'react'
import ReUseAbleTabs from 'shared/ReUseAbleTabs'

import DashboardLayout from 'layout';
import DashboardTopTitle from 'components/DashboardTopTitle';
import { AddCircleOutline, Download } from '@mui/icons-material';
import Information from './SubCards/Information';

import Transactions from './SubCards/Transactions';
import Insights from './SubCards/Insights';

import { useNavigate } from 'react-router-dom';
import Order from './SubCards/Orders';
import Referrals from './SubCards/Referrals';


const tabs = [
  {
    label: "Information",
    path: "/affliates/information",
    Component: <Information />,
  },
  {
    label: "Insights",
    path: "/affliates/insights",
    Component: <Insights />,
  },
  {
    label: "Transactions",
    path: "/affliates/transactions",
    Component: <Transactions />,
  },
  {
    label: "Orders",
    path: "/affliates/orders",
    Component: <Order />,
  },
  {
    label: "Referrals",
    path: "/affliates/referrals",
    Component: <Referrals />,
  },
];

const AffliatesSub = () => {
  const navigate = useNavigate()
  return (
    <DashboardLayout>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "auto" }}>
          <Box>
            <DashboardTopTitle
              title="Affliates"
              subtitle="Showing data over the last 30 days."
              onClickFirst={() => { }}
              firstBtnIcon={<Download />}
              firstBtnTitle="Export CSV"
              secondBtnIcon={<AddCircleOutline />}
              secondBtnTitle="Blacklist User"
              firstBtn
              secondBtn
              secondBgColor="#DA4733"
            />

          </Box>
          <Box>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default AffliatesSub
