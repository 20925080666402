import { Box, Chip, Typography } from '@mui/material';
import DashboardLayout from '../../layout';
import DTable from '../Dashboard/Table';
import TableSearch from 'shared/TableSearch';
import { useNavigate } from 'react-router-dom';
import ReUseAbleTable, { Column } from 'shared/ReUseAbleTable';

export default function BookingFlight() {
  const navigate = useNavigate();

  const columns: Column[] = [
    { id: 'flightNumber', label: 'Flight Number' },
    { id: 'airline', label: 'Airline Name' },
  ];

  const rows = [
    {
      flightNumber: 'B12366',
      airline: 'Arik Air',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
    {
      flightNumber: 'B12366',
      airline: 'Qatar',
    },
  ];
  return (
    <DashboardLayout nextSideBar>
      <Box sx={{ width: '100%' }}>
        <Box mb='24px' display='flex' gap='10px'>
          <div>
            <Typography color='#101828' fontSize='24px' fontWeight={600}>
              Manage Bookings
            </Typography>
          </div>
        </Box>
        <TableSearch
          noBgColor
          p='0px'
          searchPlaceHolder='Booking ID, User, Flight/Ride ...'
        />

        <ReUseAbleTable
          columns={columns}
          rows={rows}
          onClick={() => navigate('/bookings/flight/1')}
        />
      </Box>
    </DashboardLayout>
  );
}
