import React, { useState } from 'react';
import DashboardLayout from '../layout';
import Box from '@mui/material/Box';
import DashboardTopTitle from '../components/DashboardTopTitle';
import {
  AddCircleOutline,
  Download,
  Link,
  NavigateNext,
  SelectAll,
  SelectAllSharp,
  SendOutlined,
} from '@mui/icons-material';
import MessageTable from '../components/MessageTable';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import { Avatar, Button, Card, TextField } from '@mui/material';

const Messages = () => {
  const [showChat, setShowChat] = useState(false);
  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title='Messages'
          onClickSecond={() => setShowChat(true)}
          secondBtnIcon={<AddCircleOutline />}
          secondBtnTitle='New Message'
          secondBtn
        />
        <MessageTable />
      </Box>
      {/* <Backdrop
        open={showChat}
        onClick={() => setShowChat(false)}
        sx={{ display: "grid", placeContent: "flex-end", zIndex: 10000 }}
      >
        <ChatCard onClick={() => setShowChat(false)} />
      </Backdrop> */}
      {showChat && (
        <Box height='100%' position='absolute' top={35} right={0}>
          <ChatCard onClick={() => setShowChat(false)} />
        </Box>
      )}
    </DashboardLayout>
  );
};

export default Messages;

const ChatCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      bgcolor='#FFFFFF'
      width='auto'
      height='85vh'
      borderRadius='8px'
      border='1.5px solid #E4E7EC'
      m={4}
      sx={{ scrollBehavior: 'smooth', overflowY: 'scroll' }}
    >
      <Box position='relative'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            borderBottom: '1px solid #E4E7EC',
            height: '77px',
            padding: '24px',
          }}
        >
          <Avatar />
          <Box>
            <Typography fontWeight={600} color='#101828' fontSize={14}>
              Teji Ude
            </Typography>
            <Typography
              fontSize={12}
              color='#039855'
              display='flex'
              gap='4px'
              alignItems='center'
            >
              <Box
                bgcolor='#039855'
                height='5px'
                width='5px'
                borderRadius='100%'
              />
              online
            </Typography>
          </Box>
        </Box>
        <Box p={2}>
          <SenderMessage
            message=" Hello, I have a package that was supposed to be delivered today, but it
          hasn't arrived yet. Can you help me track its status?"
            time='2:20'
          />
          <RecieverMessage
            time='2:20'
            message="Hello, I have a package that was supposed to be delivered today, but it hasn't arrived yet. Can you help me track its status?"
          />
          <SenderMessage
            message='Yes, the tracking number is 1234567890.'
            time='2:20'
          />
          <RecieverMessage message='Thank you for providing the tracking number. Let me check the status of your package for you. Please bear with me for a moment.' />
          <RecieverMessage
            time='2:20'
            message='I apologize for the inconvenience, but it seems there has been a delay in the delivery of your package. According to our records, it is currently in transit and expected to arrive by tomorrow. Rest assured, we are actively monitoring its progress.'
          />
        </Box>
        <Box
          display='flex'
          width='100%'
          mb={2}
          alignItems='center'
          gap={1}
          position='fixed'
          bottom={25}
        >
          <Link />
          <TextField
            variant='standard'
            InputProps={{ disableUnderline: true }}
            placeholder='Send your message here'
            sx={{
              p: '10px 0px  10px 14px',
              borderRadius: '100px',
              bgcolor: '#F9F9F9',
              mr: '10px',
              width: '375px',
            }}
          />
          <Box
            height='44px'
            width='44px'
            bgcolor='#379E66'
            borderRadius='100%'
            p='10px'
          >
            <SendOutlined
              sx={{
                color: 'white',
                height: '24px',
                width: '24px',
                cursor: 'pointer',
              }}
              onClick={onClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SenderMessage = ({
  message,
  time,
}: {
  message: string;
  time?: string;
}) => {
  return (
    <Box mb='12px' width='100%'>
      <Box width='450px'>
        <Typography
          fontSize={13}
          width='80%'
          color='#111A27'
          bgcolor='#E4E7EC'
          p='10px 14px 10px 14px'
          borderRadius='8px 8px 8px 0px'
        >
          {message}
        </Typography>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          width='80%'
        >
          {time && <Typography fontSize={12}>Sent: {time}</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

const RecieverMessage = ({
  message,
  time,
}: {
  message: string;
  time?: string;
}) => {
  return (
    <Box
      mb='12px'
      display='grid'
      alignItems='end'
      justifyContent='flex-end'
      width='350px'
    >
      <Box>
        <Typography
          fontSize={13}
          color='white'
          bgcolor='#101828'
          p='10px 14px 10px 14px'
          borderRadius='8px 8px 0px 8px'
        >
          {message}
        </Typography>
        {time && (
          <Typography
            fontSize={12}
            display='flex'
            alignItems='end'
            justifyContent='flex-end'
          >
            Sent: {time}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
