import { Box, Chip, Grid, Slider, Typography } from "@mui/material";
import React from "react";
import SettingsTextField from "../SettingsTextField";
import SettingsButton from "../SettingsButton";
import {
  Close,
  Email,
  Lock,
  LockOutlined,
  Password,
  PersonOutline,
} from "@mui/icons-material";

const Profile = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box
            p="24px"
            border="1px solid #E4E7EC"
            bgcolor="#FFFFFF"
            borderRadius="12px"
          >
            <Typography fontSize={20} fontWeight={600} color="#1A1A21">
              Profile Settings
            </Typography>
            <Typography color="#8C94A6" fontSize={14} mt="8px">
              These are your personal details, they are visible to the public
            </Typography>
            <Box
              my="32px"
              p="20px 16px"
              borderRadius="10px"
              border="1px solid #D0D5DD"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="12px"
            >
              <Chip
                sx={{
                  bgcolor: "#4881F1",
                  color: "#FFFFFF",
                  fontSize: "8px",
                  fontWeight: 600,
                }}
                size="small"
                label="PNG"
              />
              <div style={{ width: "100%" }}>
                <Typography fontSize={14} color="#1D2739">
                  Uploading Profile Image
                </Typography>
                <Slider />
                <Typography fontSize={11} color="#98A2B3">
                  Profilepic2.PNG | 45% Completed
                </Typography>
              </div>
              <Close />
            </Box>
            <SettingsTextField
              type="text"
              title="Full Name"
              placeholder="John Brown Mercie"
              icon={<PersonOutline sx={{ color: "#667185" }} />}
            />
            <Box height="24px" />
            <SettingsTextField
              type="text"
              title="Email"
              placeholder="johnbrown@gmail.com"
              icon={<Email sx={{ color: "#667185" }} />}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "16px",
                marginTop: "24px",
              }}
            >
              <SettingsButton title="Cancel" variant="outlined" />
              <SettingsButton title="Save Changes" variant="contained" />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            p="24px"
            border="1px solid #E4E7EC"
            bgcolor="#FFFFFF"
            borderRadius="12px"
          >
            <Typography fontSize={20} fontWeight={600} color="#1A1A21">
              Update Password
            </Typography>
            <Typography color="#8C94A6" fontSize={14} mt="8px">
              Enter your current password to make update
            </Typography>
            <Box height="32px" />
            <SettingsTextField
              type="text"
              title="Current Password"
              placeholder="Enter Password"
              icon={<LockOutlined sx={{ color: "#667185" }} />}
            />
            <Box height="24px" />
            <SettingsTextField
              type="text"
              title="New Password"
              placeholder="Enter New Password"
              icon={<LockOutlined sx={{ color: "#667185" }} />}
            />
            <div
              style={{
                marginTop: "24px",
                width: "220px",
              }}
            >
              <SettingsButton title="Update Password" variant="contained" />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
