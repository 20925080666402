import { Avatar, Box, Typography } from "@mui/material";
import move9jaLogo from "../assets/logo.svg";
import homeIcon from "../assets/home-icon.svg";
import eventIcon from "../assets/event-icon.svg";
import walletIcon from "../assets/wallet.svg";
import usersIcon from "../assets/users.svg";
import messageIcon from "../assets/message.svg";
import parkIcon from "../assets/parks.svg";
import location from "../assets/location.svg";
import routes from "../assets/routes.svg";
import analytics from "../assets/analytics.svg";
import settings from "../assets/settings.svg";
import ads from "../assets/ads.svg";
import promo from "../assets/promo.svg";
import { ArrowDropDown, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import profile from "../assets/profile.jpg";

const SideBar = ({ onClickAnalytics }: { onClickAnalytics?: () => void }) => {
  const navigate = useNavigate();

  const [openTabs, setOpenTabs] = useState<{ events: boolean; users: boolean }>({
    events: false,
    users: false,
  });

  const toggleTab = (id: "events" | "users") => {
    setOpenTabs((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  return (
    <Box sx={{ overflowY: 'scroll', scrollbarWidth: 'none' }} width="100%" height="100vh" borderRight="1px solid #E4E7EC" pt="24px">
      <Box height="603.26px">
        <Box p="8px 24px" mb="4px">
          <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        </Box>
        <Box p="0px 8px" mb="12px" borderBottom="1px solid #E4E7EC">
          <SideBarCards title="Dashboard" icon={homeIcon} path="/dashboard" />
          <SideBarCards
            title="Manage Orders"
            icon={eventIcon}
            itsId="events"
            path="/orders"
            showArrow
            isOpen={openTabs.events}
            toggleTab={() => toggleTab("events")}
          />
          <SideBarCards title="Wallet" icon={walletIcon} path="/wallet" />
          <SideBarCards
            title="Users Management"
            icon={usersIcon}
            path=""
            itsId="users"
            showArrow
            isOpen={openTabs.users}
            toggleTab={() => toggleTab("users")}
          />
          <SideBarCards title="Parks" icon={parkIcon} path="/parks" />
          <SideBarCards title="Messages" icon={messageIcon} path="/messages" />
        </Box>
        <Box p="0px 8px" mb="12px">
          <Typography p="0px 16px 0px 12px" color="#98A2B3" fontSize={14}>
            Others
          </Typography>
          <Box>
            <SideBarCards title="Track" icon={location} path="/track" />
            <SideBarCards
              title="Avaliable Routes"
              icon={routes}
              path="/routes"
            />
            <AnalyticsCards
              title="Analytics"
              icon={analytics}
              path="/analytics"
              onClick={onClickAnalytics}
            />
          </Box>
          <Typography
            p="12px 16px"
            color="#DA4733"
            fontSize={14}
            fontWeight={700}
          >
            Emergencies
          </Typography>
        </Box>
        <Box height="408.74px" />
        <Box p="0px 8px" mb="12px">
          <Box>
            <SideBarCards title="Settings" icon={settings} path="/settings" />
            <SideBarCards title="Create Ad" icon={ads} path="/create-ad" />
            <SideBarCards title="Promo" icon={promo} path="/promo" />
          </Box>
        </Box>
        <Box
          p="12px 24px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="12px"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Avatar sx={{ height: 40, width: 40 }} src={profile} />
            <div>
              <Typography color="#101928" fontSize={14} fontWeight={600}>
                David Fayemi
              </Typography>
              <Typography color="#475367" fontSize={14}>
                David@rayna.ui
              </Typography>
            </div>
          </div>
          <Logout sx={{ height: 20, width: 20 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default SideBar;

interface SideBarCardsProps {
  title: string;
  icon: string;
  path: string;
  showArrow?: boolean;
  itsId?: string;
  onClick?: () => void;
  toggleTab?: () => void;
  isOpen?: boolean;
}

const SideBarCards = ({
  title,
  icon,
  path,
  showArrow,
  itsId,
  onClick,
  toggleTab,
  isOpen
}: SideBarCardsProps) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "12px 16px",
          gap: "12px",
          my: "4px",
          bgcolor:
            window.location.pathname === path ? "#E8FFF2" : "transparent",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (showArrow) {
            toggleTab && toggleTab();
          } else {
            navigate(path);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            borderRadius: "4px",
          }}
        >
          <img src={icon} alt="" height={20} width={20} />
          <Typography fontSize={14}>{title}</Typography>
        </div>
        {showArrow && (
          <ArrowDropDown />
        )}
      </Box>
      {isOpen && (
        <div>
          {Extra.filter((items) => items.id === itsId).map((item) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: "12px 16px 12px 48px",
                gap: "12px",
                my: "4px",
                //  bgcolor:
                //             window.location.pathname === path ? "#E8FFF2" : "transparent",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(item.link);
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "4px",
                }}
              >
                <Typography fontSize={14}>{item.title}</Typography>
              </div>
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
};

const Extra = [
  {
    id: "users",
    title: "Customers",
    link: "/customers",
  },
  {
    id: "users",
    title: "Park Managers",
    link: "/park-managers",
  },
  {
    id: "users",
    title: "Affliates",
    link: "/affliates",
  },
  {
    id: "events",
    title: "Orders",
    link: "/orders",
  },

  {
    id: "events",
    title: "Travels",
    link: "/travels",
  },
];

const AnalyticsCards = ({
  title,
  icon,
  path,
  showArrow,
  itsId,
  onClick,
}: {
  title: string;
  icon: string;
  path: string;
  showArrow?: boolean;
  itsId?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const [otherTabs, setOtherTabs] = useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "12px 16px",
          gap: "12px",
          my: "4px",
          //bgcolor: "#E8FFF2",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            borderRadius: "4px",
          }}
        >
          <img src={icon} alt="" height={20} width={20} />
          <Typography fontSize={14}>{title}</Typography>
        </div>
        {showArrow && (
          <ArrowDropDown onClick={() => setOtherTabs(!otherTabs)} />
        )}
      </Box>
    </Box>
  );
};



