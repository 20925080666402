import { Box, Button, TextField, Typography, InputLabel } from '@mui/material'
import DashboardTopTitle from 'components/DashboardTopTitle'
import DashboardLayout from 'layout'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import StateChangerUI from 'shared/StateChangerUI'

export default function AssignDriverPot() {

    const { state } = useLocation()

    const { row } = state || {}

    const { control, handleSubmit } = useForm({})
    return (
        <DashboardLayout nextSideBar subMenu goTo='/bookings/assigndriver'>
            <DashboardTopTitle title='Assign Driver' />

            {/* the state changing UI */}
            <StateChangerUI />

            <Box p='32px 128px 32px 32px' bgcolor='white'>
                <Typography fontSize={20} fontWeight={700}>Driver & Vehicle's Information</Typography>

                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Driver's Name
                        </InputLabel>
                        <Controller name='' control={control} defaultValue={row.name} render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Driver's Phone Number
                        </InputLabel>
                        <Controller name='' control={control} defaultValue={row.contact.phone} render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Email
                        </InputLabel>
                        <Controller name='' control={control} defaultValue={row.contact.email} render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Car Type
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='grid' gridTemplateColumns='1fr 1fr' gap='24px' mt='24px'>

                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            License
                        </InputLabel>
                        <Controller name='' control={control} defaultValue='' render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} />
                    </Box>
                    <Box>
                        <InputLabel shrink sx={{ fontWeight: 800 }}>
                            Car Color
                        </InputLabel>
                        <Controller name='' control={control} defaultValue={row.carDetails.color} render={({ field }) => (
                            <TextField variant='standard' InputProps={{ disableUnderline: true }} sx={{ bgcolor: '#F9F9F9', borderRadius: '8px', p: '10px 14px' }} fullWidth {...field} />
                        )} /></Box>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='center' mt='24px'>
                    <Button sx={{ width: '300px', p: '10px 14px', borderRadius: '8px', fontWeight: 400 }} variant='contained'>Assign Driver</Button>
                </Box>
            </Box>

        </DashboardLayout>
    )
}
