import B from '../../../assets/dashboard(flight&ride)/building.svg';
import C from '../../../assets/dashboard(flight&ride)/calender.svg';
import P from '../../../assets/dashboard(flight&ride)/person.svg';
import W from '../../../assets/dashboard(flight&ride)/wallet.svg';
import U from '../../../assets/dashboard(flight&ride)/alty.svg';
import D from '../../../assets/dashboard(flight&ride)/blty.svg';

export const flightShowUps = [
  {
    title: 'Total Bookings',
    value: '10,287',
    icon: C,
    rating: '8.5',
    arrow: U,
    bg: '#FFEDCE',
    res: 'Up from yesterday',
  },
  {
    title: 'Total User',
    value: '40,287',
    icon: P,
    rating: '8.5',
    arrow: U,
    bg: '#E8FFF2',
    res: 'Up from yesterday',
  },
  {
    title: 'Total Sales',
    value: '5000',
    icon: W,
    rating: '8.5',
    arrow: D,
    aColor: '#DA4733',
    bg: '#E8FFF2',
    res: 'Down from yesterday',
  },
  {
    title: 'Total Airlines',
    value: '10',
    icon: B,
    rating: '8.5',
    arrow: D,
    aColor: '#DA4733',
    bg: '#E8FFF2',
    res: 'Down from yesterday',
  },
];

export const rideShowUps = [
  {
    title: 'Total Bookings',
    value: '10,287',
    icon: C,
    rating: '8.5',
    arrow: U,
    bg: '#FFEDCE',
    res: 'Up from yesterday',
  },
  {
    title: 'Total User',
    value: '40,287',
    icon: P,
    rating: '8.5',
    arrow: U,
    bg: '#E8FFF2',
    res: 'Up from yesterday',
  },
  {
    title: 'Total Sales',
    value: '5000',
    icon: W,
    rating: '8.5',
    arrow: D,
    aColor: '#DA4733',
    bg: '#E8FFF2',
    res: 'Down from yesterday',
  },
];
