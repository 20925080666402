import { CalendarMonthOutlined, List } from "@mui/icons-material";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { Box, Typography } from "@mui/material";
import React from "react";
import contain from "../assets/contain.svg";
import search from "../assets/search-icon.svg";

const DashboardTableEvents = ({
  children,
  onClickDays,
  onClickWeek,
  onClickMonth,
  goToNext,
  goToPrev,
  goToToday,
}: {
  children?: React.ReactNode;
  onClickDays?: () => void;
  onClickWeek?: () => void;
  onClickMonth?: () => void;
  goToNext?: () => void;
  goToPrev?: () => void;
  goToToday?: () => void;
}) => {
  return (
    <Box
      width="auto"
      // "1116px"
      height="auto"
      border="1px solid #E4E7EC"
      borderRadius="10px"
    >
      <Box
        height="68px"
        bgcolor="#FFFFFF"
        sx={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
        width="100%"
        p="16px"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="12px"
          >
            <CustomIcon
              iconLeft={<CalendarMonthOutlined />}
              iconRight={<List />}
            />

            <Typography
              border="1px solid #D0D5DD"
              borderRadius="8px"
              p="8px 16px"
              fontSize={14}
              fontWeight={600}
              onClick={goToToday}
              sx={{ cursor: "pointer" }}
            >
              Today
            </Typography>
            <CustomIcon
              iconLeft={
                <NavigateBefore onClick={goToPrev} sx={{ cursor: "pointer" }} />
              }
              iconRight={
                <NavigateNext onClick={goToNext} sx={{ cursor: "pointer" }} />
              }
            />
          </Box>
          <Typography fontSize={18}>July, 2023</Typography>
          <Box display="flex" alignItems="center" gap="12px">
            <CustomDays
              first="Day"
              second="Week"
              third="Month"
              fourth="Year"
              onClickDays={onClickDays}
              onClickMonth={onClickMonth}
              onClickWeek={onClickWeek}
            />
            <Box
              border="1px solid #D0D5DD"
              borderRadius="8px"
              p="8px"
              fontSize={14}
              fontWeight={600}
            >
              <img src={contain} alt="" height={20} width={20} />
            </Box>
            <Box
              border="1px solid #D0D5DD"
              borderRadius="8px"
              p="8px"
              fontSize={14}
              fontWeight={600}
            >
              <img src={search} alt="" height={20} width={20} />
            </Box>
          </Box>
        </div>
      </Box>
      <Box> {children}</Box>
    </Box>
  );
};

export default DashboardTableEvents;

export const CustomIcon = ({
  iconLeft,
  iconRight,
}: {
  iconLeft: React.ReactNode;
  iconRight: React.ReactNode;
}) => {
  return (
    <Box display="flex">
      <div
        style={{
          padding: "4px",
          fontSize: "12px",
          border: "1px solid #D0D5DD",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      >
        {iconLeft}
      </div>
      <div
        style={{
          padding: "4px",
          border: "1px solid #D0D5DD",

          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        {iconRight}
      </div>
    </Box>
  );
};

const CustomDays = ({
  first,
  second,
  third,
  fourth,
  onClickDays,
  onClickWeek,
  onClickMonth,
}: {
  first: string;
  second: string;
  third: string;
  fourth: string;
  onClickDays?: () => void;
  onClickWeek?: () => void;
  onClickMonth?: () => void;
}) => {
  return (
    <Box display="flex">
      <Typography
        sx={{
          padding: "10px 16px",
          fontSize: "12px",
          border: "1px solid #D0D5DD",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          cursor: "pointer",
        }}
        onClick={onClickDays}
      >
        {first}
      </Typography>
      <Typography
        sx={{
          padding: "10px 16px",
          fontSize: "12px",
          border: "1px solid #D0D5DD",
          cursor: "pointer",
        }}
        onClick={onClickWeek}
      >
        {second}
      </Typography>
      <Typography
        sx={{
          padding: "10px 16px",
          fontSize: "12px",
          border: "1px solid #D0D5DD",
          cursor: "pointer",
        }}
        onClick={onClickMonth}
      >
        {third}
      </Typography>
      <Typography
        sx={{
          padding: "10px 16px",
          fontSize: "12px",
          border: "1px solid #D0D5DD",

          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        {fourth}
      </Typography>
    </Box>
  );
};
