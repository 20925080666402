import { Box } from "@mui/material";
import OrderTable from "../../../shared/OrderTable";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import { AddCircleOutline, Download } from "@mui/icons-material";

const Order = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Order"
        fontSize={20}
        subtitle="Showing data over the last 30 days."
        onClickFirst={() => {}}
        firstBtnIcon={<Download />}
        firstBtnTitle="Export CSV"
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="Add Employee"
        firstBtn
        secondBtn
      />
      <OrderTable />
    </Box>
  );
};

export default Order;
