import React, { useState } from "react";
import DashboardLayout from "../../layout";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Add,
  AddCircleOutline,
  ArrowDownward,
  ArrowUpward,
  CloudDownloadOutlined,
  Error,
  MoreVert,
  NavigateNext,
  RemoveRedEyeOutlined,
} from "@mui/icons-material";
import access from "assets/access.svg";
import checkCircle from "assets/check-circle.svg";
import { useNavigate } from "react-router-dom";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import WalletTable from "./WalletTable";

const Wallet = () => {
  const [showCard, setShowCard] = useState(false);

  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title='Wallet'
          subtitle='Your current sales summary and activity.'
          onClickFirst={() => {}}
          firstBtnIcon={<CloudDownloadOutlined />}
          firstBtnTitle='Export report'
          secondBtnIcon={<Add />}
          secondBtnTitle='Withdraw Funds'
          onClickSecond={() => setShowCard(true)}
          firstBtn
          secondBtn
        />
        <Card
          sx={{
            bgcolor: "#000",
            width: "472px",
            height: "226px",
            borderRadius: "10px",
            p: "17px 27px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            my: "24px",
          }}
        >
          <Box
            display='flex'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <div>
              <Typography color='#98A2B3' fontSize={12} mb='16px'>
                Available Balance
              </Typography>
              <Typography fontWeight={600} fontSize={32} color='#FFFFFF'>
                $
              </Typography>
              <Typography fontWeight={600} fontSize={32} color='#FFFFFF'>
                140,000.00
              </Typography>
            </div>

            <RemoveRedEyeOutlined
              sx={{ color: "#667185", height: "32px", width: "32px" }}
            />
          </Box>
          <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            <Typography color='#D0D5DD' fontSize={12}>
              <span style={{ color: "#98A2B3" }}>Payout balance: </span>{" "}
              $139,900.99
            </Typography>
            <Error sx={{ color: "red", height: "16px", width: "16px" }} />
          </div>
        </Card>
        <Grid
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap='8px'
        >
          <ReuseAbleTodayCards
            title="Today's revenue"
            value={1280}
            chipValue='10%'
            positive
          />
          <ReuseAbleTodayCards
            title="Today's Remittance Submitted"
            value={14}
            chipValue='12%'
            positive
          />
          <ReuseAbleTodayCards
            title="Today's Commission Paid"
            value={91.42}
            chipValue='100%'
            negative
          />
        </Grid>
      </Box>
      <Box height='24px' />
      <DashboardTopTitle
        title='Transaction History'
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle='New Appointment'
        onClickSecond={() => {}}
        secondBtn
      />
      <WalletTable onClick={() => {}} />
      <Backdrop sx={{ zIndex: 1111111111 }} open={showCard}>
        <WithdrawCard onClick={() => setShowCard(false)} />
      </Backdrop>
    </DashboardLayout>
  );
};

export default Wallet;

interface ReuseAbleTodayCardsProp {
  title: string;
  value: number;
  chipValue: string;
  positive?: Boolean;
  negative?: Boolean;
}

export const ReuseAbleTodayCards = ({
  title,
  value,
  chipValue,
  positive,
  negative,
}: ReuseAbleTodayCardsProp) => {
  return (
    <Box
      width='100%'
      border='1px solid #E4E7EC'
      borderRadius='12px'
      bgcolor='#FFFFFF'
      height='auto'
    >
      <Box p='24px'>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <Typography textAlign='left' color='#475467' fontSize={14}>
            {title}
          </Typography>
          <MoreVert />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign='left'
            color='#101828'
            fontSize={30}
            fontWeight={600}
          >
            ${value}
          </Typography>
          {positive && (
            <Chip
              label={chipValue}
              size='small'
              sx={{ fontSize: "14px", bgcolor: "#ABEFC6", color: "#067647" }}
              icon={
                <ArrowUpward
                  sx={{ height: "12px", width: "12px" }}
                  color='primary'
                />
              }
            />
          )}
          {negative && (
            <Chip
              label={chipValue}
              size='small'
              sx={{ fontSize: "14px", bgcolor: "#FECDCA", color: "#B42318" }}
              icon={
                <ArrowDownward
                  sx={{ height: "12px", width: "12px" }}
                  color='error'
                />
              }
            />
          )}
        </div>
      </Box>
      <Divider />
      <Box p='12px'>
        <Typography
          color='#379E66'
          textAlign='right'
          fontSize={14}
          fontWeight={600}
        >
          View report
        </Typography>
      </Box>
    </Box>
  );
};

const WithdrawCard = ({ onClick }: { onClick: () => void }) => {
  let [currentPage, setCurrentPage] = useState<number>(0);

  const goToNextPage = () => {
    setCurrentPage(++currentPage);
  };

  const navigate = useNavigate();

  return (
    <Box
      bgcolor='#FFFFFF'
      width='588px'
      height='494px'
      borderRadius='8px'
      border='1.5px solid #E4E7EC'
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #E4E7EC",
          height: "77px",
          padding: "24px",
        }}
      >
        <Typography fontWeight={600} fontSize={18} color='#101828'>
          Withdraw from wallet
        </Typography>
        <Button
          sx={{ borderRadius: "8px" }}
          variant='outlined'
          onClick={onClick}
        >
          Close
        </Button>
      </div>
      {/* First */}
      {currentPage === 0 && (
        <div style={{ padding: "12px 24px" }}>
          <Typography fontSize={14}>How much?</Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "180px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography fontWeight={600} fontSize={32}>
              $ 140,000.00
            </Typography>
          </div>

          <Card
            sx={{
              border: "1px solid #F2F4F7",
              mb: "12px",
              borderRadius: "8px",
              p: "15px 13px",
            }}
          >
            <Typography fontSize={9} color='#98A2B3' mb='8px'>
              BANK ACCOUNT
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={access} alt='' height={26} width={26} />
                <div>
                  <Typography fontWeight={600} fontSize={12}>
                    John Doe
                  </Typography>
                  <Typography fontSize={12}>.... 9872</Typography>
                </div>
              </div>
              <NavigateNext />
            </Box>
          </Card>
          <Button
            fullWidth
            variant='contained'
            onClick={goToNextPage}
            sx={{ fontSize: "12px", p: "12px 24px", borderRadius: "8px" }}
          >
            Confirm
          </Button>
        </div>
      )}
      {/* Second */}
      {currentPage === 1 && (
        <div style={{ padding: "12px 24px" }}>
          <Typography fontSize={24} fontWeight={600} mb='15px' color='#171721'>
            Verify action
          </Typography>
          <Typography fontSize={14} color='#667085' width='324px'>
            Enter the otp code that was sent to your phone number at{" "}
            <span style={{ fontWeight: "bold" }}>080738394992</span>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "156px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          <Button
            fullWidth
            variant='contained'
            sx={{
              fontSize: "12px",
              p: "12px 24px",
              borderRadius: "8px",
              mb: "24px",
            }}
            onClick={goToNextPage}
          >
            Confirm
          </Button>
          <Typography fontSize={14} color='#667085' textAlign='center'>
            Didn’t Receive a code?{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#1D2939",
              }}
            >
              Resend code in 20s
            </span>
          </Typography>
        </div>
      )}
      {/* Third */}
      {currentPage === 2 && (
        <Box
          sx={{
            padding: "60px 24px 12px 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "200px",
              gap: "24px",
              marginBottom: "80px",
            }}
          >
            <img src={checkCircle} alt='' height='58.5px' width='58.5px' />
            <div
              style={{ width: "298px", height: "104px", textAlign: "center" }}
            >
              <Typography
                fontSize={18}
                color='#101828'
                fontWeight={600}
                mb='24px'
              >
                Withdrawal Successful
              </Typography>

              <Typography color='#667085' fontSize={14}>
                You've successfully completed your delivery, and we appreciate
                your dedication to making it happen.
              </Typography>
            </div>
          </div>

          <Button
            fullWidth
            variant='contained'
            sx={{
              fontSize: "12px",
              p: "12px 24px",
              borderRadius: "8px",
              mb: "24px",
            }}
            onClick={() => navigate("/dashboard")}
          >
            Go Home
          </Button>
        </Box>
      )}
    </Box>
  );
};
