import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { ReuseAbleTodayCards } from "../../Wallet/Wallet";
import InsightChart from "../InsightChart";
import ReUseAbleTabs from "shared/ReUseAbleTabs";
const tabs = [
  { label: "12 months", Component: <InsightChart />, path: "" },
  { label: "30 days", Component: <InsightChart />, path: "" },
  { label: "7 days", Component: <InsightChart />, path: "" },
  { label: "24 hours", Component: <InsightChart />, path: "" },
];

const Insights = () => {
  return (
    <Box>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="24px"
      >
        <ReuseAbleTodayCards
          chipValue="10%"
          title="Wallet Balance"
          value={1280}
          positive
        />
        <ReuseAbleTodayCards
          chipValue="12%"
          title="Total Paid"
          value={14}
          positive
        />
        <ReuseAbleTodayCards
          chipValue="100%"
          title="Total Travel"
          value={45}
          negative
        />
      </Grid>
      <Divider sx={{ my: "24px" }} />
      <Box
        border="1px solid #E4E7EC"
        width="100%"
        borderRadius="10px"
        height="auto"
        p="24px"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontWeight={600}>
            Travel traffic
          </Typography>
          <Button
            variant="contained"
            sx={{
              bgcolor: "transparent",
              border: "1px solid #e4e7ec",
              color: "black",
              fontWeight: 400,
              borderRadius: "10px",
            }}
          >
            View report
          </Button>
        </Box>
        <ReUseAbleTabs tabs={tabs} />
      </Box>
    </Box>
  );
};

export default Insights;
