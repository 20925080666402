import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { createParkManager } from 'services/park';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { handleMutationError } from 'utils/handler';

const schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  username: yup.string().required('Username is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  park_id: yup.string().required('Park ID is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  dob: yup
    .date()
    .required('Date of Birth is required')
    .typeError('Invalid Date'),
  phone: yup.string().required('Phone is required'),
});

function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.startsWith('0')) {
    return '234' + phoneNumber.slice(1);
  }
  return '234' + phoneNumber;
}

const CreateParkManager = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const mutation = useMutation({
    mutationFn: async (data) => {
      return await createParkManager(data);
    },
    onSuccess: (data) => {
      console.log('on Success', data);
      toast.success(data?.message);
    },
    onError: (err) => {
      handleMutationError(err, 'data');
      console.log('Err', err);
    },
  });

  const onSubmit = (data: any) => {
    mutation.mutate({ ...data, phone: formatPhoneNumber(data?.phone) });
    console.log({ ...data, phone: formatPhoneNumber(data?.phone) });
  };

  return (
    <Box>
      <Box
        height='72px'
        p='0px 32px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <Typography variant='h6'>Your Logo Here</Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        //height='calc(100vh - 72px)'
        height='auto'
      >
        <Box
          width='510px'
          p='24px'
          border='1px solid #E4E7EC'
          borderRadius='8px'
        >
          <Typography variant='h5' mb='24px'>
            User Registration
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                First Name
              </InputLabel>
              <Controller
                name='first_name'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your first name'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.first_name}
                    helperText={
                      errors.first_name ? errors.first_name.message : ''
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Last Name
              </InputLabel>
              <Controller
                name='last_name'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your last name'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.last_name}
                    helperText={
                      errors.last_name ? errors.last_name.message : ''
                    }
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Username
              </InputLabel>
              <Controller
                name='username'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your username'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ''}
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Email
              </InputLabel>
              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your email'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Park ID
              </InputLabel>
              <Controller
                name='park_id'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your park ID'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.park_id}
                    helperText={errors.park_id ? errors.park_id.message : ''}
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Password
              </InputLabel>
              <Controller
                name='password'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type={showPassword ? 'password' : 'text'}
                    placeholder='Enter your password'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <Button onClick={() => setShowPassword(!showPassword)}>
                          {!showPassword ? 'Hide' : 'Show'}
                        </Button>
                      ),
                    }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Date of Birth
              </InputLabel>
              <Controller
                name='dob'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type='date'
                    variant='standard'
                    InputProps={{ disableUnderline: true }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.dob}
                    helperText={errors.dob ? errors.dob.message : ''}
                  />
                )}
              />
            </FormControl>
            <FormControl variant='standard' fullWidth>
              <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                Phone
              </InputLabel>
              <Controller
                name='phone'
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    placeholder='Enter your phone number'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position='start'>+234</InputAdornment>
                      ),
                    }}
                    sx={{
                      bgcolor: '#F9F9F9',
                      mt: 2,
                      mb: 2,
                      py: 1,
                      px: 2,
                      borderRadius: '10px',
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone ? errors.phone.message : ''}
                  />
                )}
              />
            </FormControl>
            <Button
              disabled={!!mutation.isPending}
              type='submit'
              variant='contained'
              fullWidth
              sx={{
                fontWeight: 400,
                borderRadius: '8px',
                p: '12px 24px',
              }}
            >
              {mutation.isPending ? 'Loading..' : 'Submit'}
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateParkManager;
