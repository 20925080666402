import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  TextField,
  Typography,
} from "@mui/material";

import TableSearch from "../../shared/TableSearch";

function createData(
  name: string,
  email: string,
  totalRemitted: string,
  date: string,
  status: string
) {
  return { name, email, totalRemitted, date, status };
}
const rows = [
  createData(
    "Osim Uka",
    "osimukaokpan@gmail.com",
    "$200,000.00",
    "Apr 12, 2023 | 09:32AM",
    "Completed"
  ),
];

export default function WalletTable({ onClick }: { onClick: () => void }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <TableSearch />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F0F2F5" }}>
          <TableRow>
            <TableCell height="45px" width="311px">
              <Box>
                <Typography color="#344054" fontSize={12}>
                  Name
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              Total Remitted
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="242px"
            >
              Date
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="188px"
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <TableCell component="th" scope="row" width="311px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Checkbox />
                  <Avatar sx={{ color: "black", fontWeight: "bold" }}>O</Avatar>
                  <div>
                    <Typography color="#101928" fontSize={14}>
                      {row.name}
                    </Typography>
                    <Typography color="#101928" fontSize={14}>
                      {row.email}
                    </Typography>
                  </div>
                </Box>
              </TableCell>

              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.totalRemitted}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="242px">
                <Typography color="#344054" fontSize={14}>
                  {row.date}
                </Typography>
              </TableCell>
              <TableCell align="center" width="188px">
                <Chip
                  label={row.status}
                  size="small"
                  sx={{
                    bgcolor: "#E8FFF2",
                    color: "#379E66",
                    fontSize: "14px",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
