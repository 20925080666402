import React, { useState } from "react";

import { AddCircleOutline } from "@mui/icons-material";

import Box from "@mui/material/Box";
import DashboardTopTitle from "../components/DashboardTopTitle";
import OrderTable from "../shared/OrderTable";
import DashboardLayout from "../layout";
import ReUseAbleTabs from "../shared/ReUseAbleTabs";
import TravelTable from "shared/TravelTable";

const tabs = [
  {
    label: "On-going Travels",
    path: "/travels/new-travels",
    Component: <TravelTable />,
  },
  {
    label: "All Travels",
    path: "/travels/all-travels",
    Component: <Box>All Travels</Box>,
  },
  {
    label: "Past Travels",
    path: "/travels/past-travels",
    Component: <Box>Past Travels</Box>,
  },
];

const Travels = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <Box height="24px" />
      <DashboardTopTitle
        title="Travels"
        secondBtn
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="New Appointments"
      />

      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "auto" }}>
          <Box>
            <ReUseAbleTabs tabs={tabs} />
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Travels;

const NewTravels = () => {
  return <OrderTable />;
};

const AllTravels = () => {
  return <Box>All Travels</Box>;
};

const PastTravels = () => {
  return <Box>Past Travels</Box>;
};
