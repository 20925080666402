import { Button } from "@mui/material";
import React from "react";

const SettingsButton = ({
  variant,
  title,
  border,
}: {
  variant: "contained" | "outlined";
  title: string;
  border?: string;
}) => {
  return (
    <Button
      fullWidth
      variant={variant}
      sx={{
        border: border,
        borderRadius: "8px",
        p: "16px 24px",
      }}
    >
      {title}
    </Button>
  );
};

export default SettingsButton;
