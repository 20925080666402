import React, { useState } from "react";
import DashboardLayout from "../../layout";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import { AddCircleOutline, Download } from "@mui/icons-material";
import CustomTable from "../../components/CustomTable";
import { Container, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";


const Customers = () => {
 
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title="Customers"
          subtitle="Showing data over the last 30 days."
          onClickFirst={() => {}}
          firstBtnIcon={<Download />}
          firstBtnTitle="Export CSV"
          secondBtnIcon={<AddCircleOutline />}
          secondBtnTitle="Add Employee"
          firstBtn
          secondBtn
          onClickSecond={() => navigate("/create-customers")}
        />
      </Box>

      <CustomTable onClick={() => {
        navigate('/customers/sub/')
      }} />
     
    </DashboardLayout>
  );
};

export default Customers;
