import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import Bus from "../assets/bus.svg";

import React, { ReactNode, useState } from "react";
import link from "../assets/link.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Call from "../assets/call.png";
import Messag from "../assets/messag.png";
import TheBus from "../assets/theBus.png";
import DashboardLayout from "../layout";
import DashboardTopTitle from "../components/DashboardTopTitle";
import {
  Add,
  AddCircleOutline,
  CloudUploadOutlined,
} from "@mui/icons-material";
import OrderTable from "../shared/OrderTable";
import CustomTimeline from "../components/CustomTimline";
import VehicleCard from "../components/VehicleCard";

const OrderDetails = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout subMenu>
      <DashboardTopTitle
        title="Order Details"
        onClickFirst={() => {}}
        secondBtn
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="New Appointment"
      />
      <Box bgcolor="#F2F4F7" p="14px" mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Move9jaCard title="Thu, May 23, 2024" endText="6h 40m">
              <CustomTimeline />
            </Move9jaCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Move9jaCard title="Bus Details">
              <VehicleCard onClick={() => {}} />
            </Move9jaCard>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Move9jaCard title="Park Manager's Details">
              <Profile />
            </Move9jaCard>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          border: "1px solid #E4E7EC",
          borderRadius: "12px",
          p: "24px",
          my: "12px",
          bgcolor: "#FFFFFF",
        }}
      >
        <Typography fontSize={16} fontWeight={600}>
          Cottage Medicare Park
        </Typography>
        <Typography fontSize={14} display="flex" alignItems="center" gap={0.5}>
          5.0 <Rating /> (70)
        </Typography>
        <Typography fontSize={14} color="#475367">
          18 Iwaya Rd, Yaba 101245, Lagos
        </Typography>
        <Typography fontSize={14} color="#475367">
          <span style={{ color: "#036B26" }}>Open 24 hours </span>• 0814 609
          2019
        </Typography>
      </Box>
      <Box height="24px" />
      <Passengers />
    </DashboardLayout>
  );
};

export default OrderDetails;

const Passengers = () => {
  return (
    <Box>
      <DashboardTopTitle
        title="Passengers"
        onClickFirst={() => {}}
        firstBtnIcon={<CloudUploadOutlined />}
        firstBtnTitle="Export list"
        firstBtn
        secondBtn
        secondBtnIcon={<Add />}
        secondBtnTitle="Invite"
      />
      <OrderTable />
    </Box>
  );
};

const Move9jaCard = ({
  title,
  children,
  endText,
}: {
  title: string;
  children: ReactNode;

  endText?: ReactNode | string;
}) => {
  return (
    <Box border="1px solid #E4E7EC" borderRadius="8px" overflow="hidden">
      <Box
        bgcolor="#F6F6F6"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
      >
        <Typography fontSize="12px" fontWeight={600}>
          {title}
        </Typography>
        <Typography fontSize="12px" fontWeight={600}>
          {endText}
        </Typography>
      </Box>
      <Box bgcolor="#FFFFFF" sx={{ height: "162px" }}>
        {children}
      </Box>
    </Box>
  );
};

const Profile = () => {
  return (
    <Box>
      <Box p="8px" bgcolor="#FFF">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          my="12px"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Avatar />
            <Box>
              <Typography color="#101828" fontSize={12}>
                John Doe
              </Typography>
              <Typography fontSize={10}> 4.8</Typography>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              height="36px"
              width="36px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#000"
            >
              <img src={Messag} alt="" height="20px" width="20px" />
            </Box>
            <Box
              height="36px"
              width="36px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="#12B76A"
            >
              <img src={Call} alt="" height="20px" width="20px" />
            </Box>
          </div>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="#667185" fontSize={16}>
            Total Remitted
          </Typography>
          <Typography fontSize={20} fontWeight={600}>
            NGN 419,090.00
          </Typography>
        </Box>
        <Divider sx={{ mt: "12px" }} />
        <Box
          height="49px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="text"
            sx={{
              color: "#379E66",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            View full details
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
