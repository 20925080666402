import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Chip, Typography } from '@mui/material';

interface Header {
  label: string;
  key: string;
  width?: string;
}

interface DTableProps {
  headers: Header[];
  data: Array<{ [key: string]: string | JSX.Element }>;
  onClick?: (row: { [key: string]: string }) => void;
  middleProps?: boolean;
}

export default function AppTable({
  headers,
  data,
  onClick,
  middleProps,
}: DTableProps) {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      // sx={{ borderRadius: '10px', border: '1px solid #E4E7EC' }}
    >
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead sx={{ bgcolor: '#F9FAFB' }}>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                key={header.key}
                sx={{ fontSize: '12px', fontWeight: 400, bgcolor: '#F0F2F5' }}
                align='left'
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: onClick ? 'pointer' : 'default',
              }}
              // onClick={() => onClick && onClick(row)}
            >
              {headers.map((header) => (
                <TableCell key={header.key} align='left'>
                  <Typography color='#344054' fontSize={12}>
                    {row[header.key]}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
