import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import { ChevronLeft, Search } from "@mui/icons-material";

import profile from "../assets/profile.jpg";
import notify from "../assets/notify.svg";
import mssg from "../assets/mssg.svg";
import plus from "../assets/plus.png";
import { useNavigate } from "react-router-dom";
import NextSideBar from "components/NextSideBar";

const DashboardLayout = ({
  children,
  subMenu,
  nextSideBar,
  goTo = '/'
}: {
  children: React.ReactNode;
  subMenu?: boolean;
  nextSideBar?: boolean;
  goTo?: string
}) => {
  const navigate = useNavigate();
  const [closeSideBar, setCloseSideBar] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/analytics") {
      setCloseSideBar(true);
    } else {
      setCloseSideBar(false); // Optional: Reset sidebar state for other paths
    }
  }, [window.location.pathname]);

  const handleSideBar = () => {
    navigate("/analytics");
    // Optionally, you could also set the sidebar state here before navigating
  };
  return (
    <Box
      sx={{
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none", // IE and Edge
        "scrollbar-width": "none",
      }}
    >
      <Grid
        width="100%"
        display="grid"
        sx={{ gridTemplateColumns: closeSideBar ? "100%" : "19% 81%" }}
      >
        {nextSideBar ? (
          <NextSideBar />
        ) : (
          !closeSideBar && <SideBar onClickAnalytics={handleSideBar} />
        )}



        {/* Main Content Area */}
        <Box
          bgcolor="#F9FAFB"
          sx={{
            width: "100%",
            height: "100vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": { display: "none" },
            "-ms-overflow-style": "none", // IE and Edge
            "scrollbar-width": "none", // Firefox
          }}
        >
          {/* Fixed Header */}
          <Box
            height="64px"
            borderBottom="1px solid #FFFFFF"
            bgcolor="#FFFFFF"
            p="0px 36px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            zIndex="1000"
            width="auto"
          >
            <TextField
              placeholder="Search here"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: <Search sx={{ height: 20, width: 20 }} />,
              }}
              sx={{
                bgcolor: "#F9F9F9",
                py: 1,
                px: 2,
                borderRadius: "10px",
                width: "629px",
              }}
              type="text"
            />
            <Box display="flex" alignItems="center" gap="12px">
              <Avatar
                sx={{ height: "40px", width: "40px", bgcolor: "#F0F2F5" }}
              >
                <img
                  src={mssg}
                  alt=""
                  style={{ color: "black", height: "20px", width: "20px" }}
                />
              </Avatar>
              <Avatar
                sx={{ height: "40px", width: "40px", bgcolor: "#F0F2F5" }}
              >
                <img
                  src={notify}
                  alt=""
                  style={{ color: "black", height: "20px", width: "20px" }}
                />
              </Avatar>
              <Avatar sx={{ height: "40px", width: "40px" }} src={profile} />
            </Box>
          </Box>
          {/* Sub Menu */}
          {subMenu && (
            <Box
              height="64px"
              borderBottom="1px solid #FFFFFF"
              bgcolor="#FFFFFF"
              p="0px 36px"
              display="flex"
              alignItems="center"
              zIndex="1000"
              width="auto"
            >
              <ChevronLeft
                onClick={() => navigate(goTo)}
                sx={{ cursor: "pointer" }}
              />
              <Typography ml={2} fontWeight={600}>Back</Typography>
            </Box>
          )}
          {/* Main Content with margin for fixed header */}
          <Box
            p="0px 24px"
            mt="44px"
            pb={6}
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              "-ms-overflow-style": "none", // IE and Edge
              "scrollbar-width": "none",
            }}
          >
            {children}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default DashboardLayout;
