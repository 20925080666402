import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { ReuseAbleTodayCards } from "../../Wallet/Wallet";

import ReUseAbleTabs from "shared/ReUseAbleTabs";
import InsightChart from "pages/Parks/InsightChart";

const tabs = [
  { label: "12 months", Component: <InsightChart />, path: "" },
  { label: "30 days", Component: <InsightChart />, path: "" },
  { label: "7 days", Component: <InsightChart />, path: "" },
  { label: "24 hours", Component: <InsightChart />, path: "" },
];

const Insights = () => {
  return (
    <Box>
      <Box height="120px" display="grid" gridTemplateColumns="1fr 1fr">
        <div style={{ display: "flex", alignItems: "center", gap: "41px" }}>
          <Avatar sx={{ height: "120px", width: "120px" }} />
          <Box>
            <Typography fontSize={16} fontWeight={600} color="#344054">
              John Doe
            </Typography>
            <Typography fontSize={14}>ID: 784893</Typography>
            <Typography
              fontSize={14}
              display="flex"
              alignItems="center"
              gap="4px"
            >
              4.7 <Rating /> (70)
            </Typography>
            <Typography fontSize={14}>
              KYC Level 1 Completed . 0814 609 2019
            </Typography>
          </Box>
        </div>
        <Typography fontSize={14}>Referral Code: weooeio</Typography>
      </Box>
      <Divider sx={{ my: "24px" }} />
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="24px"
      >
        <ReuseAbleTodayCards
          title="Wallet Balance"
          value={1280}
          chipValue="10%"
          positive
        />
        <ReuseAbleTodayCards
          chipValue="12%"
          title="Total Paid"
          value={14}
          positive
        />
        <ReuseAbleTodayCards
          chipValue="100%"
          title="Total Travel"
          value={45}
          negative
        />
      </Grid>
      <Divider sx={{ my: "24px" }} />
      <Box
        border="1px solid #E4E7EC"
        width="100%"
        borderRadius="10px"
        height="auto"
        p="24px"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize={18} fontWeight={600}>
            Travel traffic
          </Typography>
          <Button
            variant="contained"
            sx={{
              bgcolor: "transparent",
              border: "1px solid #e4e7ec",
              color: "black",
              fontWeight: 400,
              borderRadius: "10px",
            }}
          >
            View report
          </Button>
        </Box>
        <ReUseAbleTabs tabs={tabs} />
      </Box>
    </Box>
  );
};

export default Insights;
