import Box from "@mui/material/Box";
import TheBus from "../../../assets/theBus.png";
import TableSearch from "../../../shared/TableSearch";
import { Grid, Typography } from "@mui/material";

const Media = () => {
  return (
    <Box>
      <TableSearch />
      <Box height="24px" />
      <Box>
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6].map((_) => (
            <Grid
              item
              xs={12}
              lg={4}
              borderRadius="12px"
              height="auto"
              width="100%"
            >
              <Typography mb="12px" fontSize={14} color="#667185">
                24 Jun, 2024
              </Typography>
              <img
                src={TheBus}
                alt=""
                width="100%"
                style={{
                  objectFit: "cover",
                  borderRadius: "12px",
                  height: "244px",
                }}
                height="244px"
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
export default Media;
