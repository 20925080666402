import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  ArrowBack,
  Check,
} from "@mui/icons-material";
import move9jaLogo from "../assets/logo.svg";
import backGroundImage from "../assets/loginImage.png";
import { useState } from "react";
import AlertMessage from "../components/AlertMessage";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useMutation } from "@tanstack/react-query";
import { IUser, login } from "services/auth";

interface IFormInputs {
  email: string;
  password: string;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async (data: Pick<IUser, "email" | "password">) => {
      return await login(data);
    },
    onSuccess: (data) => {
      console.log("on Success", data);
      localStorage.setItem("token", data?.data?.token);
      navigate("/dashboard?view=interstate");
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IFormInputs) => {
    // console.log(data);
    mutation.mutate(data);
    // setSent(true);
    // navigate("/dashboard");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box height='100vh'>
      <Box
        height='72px'
        p='0px 32px'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        border='1px solid #E4E7EC'
      >
        <img src={move9jaLogo} alt='' width='162.75px' height='50.26px' />
      </Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
        <Box
          sx={{
            backgroundImage: `url(${backGroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left center",
            height: "952px",
            width: "100%",
            margin: 0,
            padding: 0,
            display: "grid",
            placeItems: "end",
          }}
        >
          <Typography
            color='#fff'
            fontSize='32px'
            fontWeight={600}
            height='92px'
            width='324px'
            mb={15}
            mr={20}
          >
            Request a bus ride, hop in, and go.
          </Typography>
        </Box>
        <Box display='flex' alignItems='center' flexDirection='column'>
          <Box width='510px' p='0px 24px' mt='70px'>
            <Box display='flex' alignItems='center' gap={1} mb='12px'>
              <ArrowBack />
              <Typography fontWeight={600}>Login</Typography>
            </Box>
            <Typography mb='42px'>Welcome Admin</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Email
                </InputLabel>
                <Controller
                  name='email'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter a registered email'
                      variant='standard'
                      InputProps={{ disableUnderline: true }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ""}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='standard' fullWidth>
                <InputLabel shrink sx={{ fontWeight: 800, mb: 1 }}>
                  Password
                </InputLabel>
                <Controller
                  name='password'
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder='Enter your password'
                      variant='standard'
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        bgcolor: "#F9F9F9",
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        borderRadius: "10px",
                      }}
                      error={!!errors.password}
                      helperText={
                        errors.password ? errors.password.message : ""
                      }
                    />
                  )}
                />
              </FormControl>
              <Button
                disabled={!!mutation.isPending}
                type='submit'
                variant='contained'
                fullWidth
                sx={{
                  fontWeight: 400,
                  borderRadius: "8px",
                  p: "12px 24px",
                }}
              >
                {mutation.isPending ? "Loading.." : "Login"}
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
      {sent && (
        <AlertMessage
          title='Login link has been sent to admin@move9ja.com'
          icon={<Check />}
          bgColor='#E8FFF2'
          textColor='#379E66'
        />
      )}
    </Box>
  );
};

export default LoginScreen;
