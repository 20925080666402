import {
  CalendarMonthOutlined,
  NavigateBefore,
  NavigateNext,
  List,
  Search,
} from "@mui/icons-material";
import OrderTable from "shared/OrderTable";
import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
import ReUseAbleTabs from "shared/ReUseAbleTabs";
import SelectDate from "components/SelectDate";

const OrdersRide = () => {
  return (
    <>
      <Box>
        <Box>
          <Box bgcolor="#FFFFFF" p="24px">
            <Box px="12px">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "12px",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="12px"
                >
                  <ButtonGroup>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <CalendarMonthOutlined />
                    </Button>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <List />
                    </Button>
                  </ButtonGroup>
                  <Typography
                    border="1px solid #D0D5DD"
                    borderRadius="8px"
                    p="8px 16px"
                    fontSize={14}
                    fontWeight={600}
                  >
                    Today
                  </Typography>

                  <ButtonGroup>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopLeftRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      <NavigateBefore />
                    </Button>
                    <Button
                      sx={{
                        border: "1px solid #D0D5DD",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <NavigateNext />
                    </Button>
                  </ButtonGroup>
                </Box>
                <Typography fontSize={18}>July, 2023</Typography>
                <SelectDate />
              </div>

              <form
                action=""
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "18px",
                  marginTop: "12px",
                }}
              >
                <TextField
                  placeholder="Ride ID"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    bgcolor: "#F9F9F9",
                    mt: 2,
                    mb: 2,
                    p: "10px 14px",
                    borderRadius: "10px",
                  }}
                  fullWidth
                  type="text"
                />

                <Button
                  sx={{
                    bgcolor: "#000000",
                    color: "#FFFFFF",
                    width: "171px",
                    p: "6.75px 11.25px",
                    height: "50px",
                    borderRadius: "3.38px",
                    fontWeight: 400,
                    fontSize: "14.06px",
                  }}
                >
                  Search
                </Button>
              </form>
            </Box>
          </Box>
          <Box>
            <OrderTable
              noTableSearch
              title={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p="14px"
                >
                  <Typography fontSize="18px" fontWeight={600}>
                    Recent Orders
                  </Typography>
                  <div>
                    <Button
                      sx={{ fontWeight: 400, color: "#00000088" }}
                      startIcon={<Search />}
                    >
                      Search
                    </Button>
                    <Button sx={{ fontWeight: 400, color: "#00000088" }}>
                      Filter
                    </Button>
                    <Button sx={{ fontWeight: 400, color: "#00000088" }}>
                      Sort
                    </Button>
                  </div>
                </Box>
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OrdersRide;
