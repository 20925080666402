import http from "services/http";

export interface IUser {
  firstName: string;
  lastName: string;
  username: string;
  dob: string;
  email: string;
  password: string;
  role: string;
  phone: string;
}

export const signUp = async (body: IUser) => {
  const res = http.post("/admin/createadmin", body);
  return (await res).data;
};

export const login = async (body: Pick<IUser, "email" | "password">) => {
  const res = http.post("/admin/adminlogin", body);
  return (await res).data;
};
