import { Box } from "@mui/material";
import React from "react";
import AdminTable from "./AdminTable";
import DashboardTopTitle from "../../../components/DashboardTopTitle";
import { AddCircleOutline, CloudDownloadOutlined } from "@mui/icons-material";

const Admins = () => {
  return (
    <Box
      bgcolor="#FFFFFF"
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
      p="24px"
    >
      <DashboardTopTitle
        title="Admins"
        fontSize={20}
        subtitle="These are your personal details, they are visible to the public."
        onClickFirst={() => {}}
        firstBtnIcon={<CloudDownloadOutlined />}
        firstBtnTitle="Export CSV"
        firstBtn
        secondBtn
        secondBtnTitle="Invite new member"
      />
      <AdminTable />
    </Box>
  );
};

export default Admins;
