
import React, { useState } from "react";
import DashboardLayout from "../../layout";
import DashboardTopTitle from "../../components/DashboardTopTitle";
import { AddCircleOutline, CloudDownloadOutlined } from "@mui/icons-material";
import CustomTable from "../../components/CustomTable";

import Box from "@mui/material/Box";

import ParkManagerCard from "../../shared/ParkManagerCard";

import { useNavigate } from "react-router-dom";


const ParkManagers = () => {

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <ParkManagerCard width="auto" />
      <Box height="24px" />
      <DashboardTopTitle
        title="Park Manager" 
        subtitle="Showing data over the last 30 days."
        onClickFirst={() => {}}
        firstBtnIcon={<CloudDownloadOutlined />}
        firstBtnTitle="Export CSV"
        firstBtn
        secondBtn
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="Create Name Manager" 
        secondBgColor="#379E66" 
        onClickSecond={() => navigate("/create-park-manager")}
      />

      <CustomTable onClick={() => navigate('/park-managers/sub/')} />
     
    </DashboardLayout>
  );
};

export default ParkManagers;
