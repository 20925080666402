import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import TableSearch from "./TableSearch";
import {
  FirstPage,
  KeyboardArrowLeft,
  LastPage,
  KeyboardArrowRight,
} from "@mui/icons-material";
import React from "react";
import { theme } from "../theme";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { useNavigate } from "react-router-dom";

interface TablePaginationProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationAction(props: TablePaginationProps) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first-page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="first-page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page === 0}
        aria-label="first-page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page === 0}
        aria-label="first-page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

function createData(
  order: string,
  from: string,
  subFrom: string,
  to: string,
  subTo: string,
  parkManagerName: string,
  parkManagerNumb: string,
  status: string,
  etaDate: string,
  etaTime: string
) {
  return {
    order,
    from,
    subFrom,
    to,
    subTo,
    parkManagerName,
    parkManagerNumb,
    status,
    etaDate,
    etaTime,
  };
}
const rows = [
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),

  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
  createData(
    "LAGJ-9080",
    "Young Legacy Park",
    "Lagos",
    "Abukbakar Gandreasf",
    "Iloin",
    "John",
    "08106748972",
    "En Route",
    "7 Jul, 2023",
    "10:00 AM"
  ),
].sort((a, b) => (a.etaDate < b.etaDate ? -1 : 1));

export default function TravelTable({ onClick }: { onClick?: () => void }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <TableSearch />
      <Table sx={{ minWidth: 650 }} aria-label="custom table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell height="45px" width="131px">
              <Typography color="#344054" fontSize={12}>
                Order ID
              </Typography>
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="174px"
            >
              From
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="219px"
            >
              To
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="219px"
            >
              Park Manager
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="149px"
            >
              Status
            </TableCell>

            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="center"
              width="131px"
            >
              ETA
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow
              key={row.order}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/travel-register");
              }}
            >
              <TableCell component="th" scope="row" width="131px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Typography color="#101928" fontSize={14}>
                    {row.order}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell align="left" width="219px">
                <Box>
                  <Typography color="#101928" fontSize={14}>
                    {row.from}
                  </Typography>
                  <Typography color="#101928" fontSize={14}>
                    {row.subFrom}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="219px">
                <Typography color="#344054" fontSize={14}>
                  <div>
                    <Typography color="#101928" fontSize={14}>
                      {row.to}
                    </Typography>
                    <Typography color="#101928" fontSize={14}>
                      {row.subTo}
                    </Typography>
                  </div>
                </Typography>
              </TableCell>

              <TableCell align="left" width="219px">
                <Typography color="#344054" fontSize={14}>
                  <Box display="flex" alignItems="center" gap="12px">
                    <Avatar />
                    <div>
                      <Typography color="#101928" fontSize={14}>
                        {row.parkManagerName}
                      </Typography>
                      <Typography color="#101928" fontSize={14}>
                        {row.parkManagerNumb}
                      </Typography>
                    </div>
                  </Box>
                </Typography>
              </TableCell>
              <TableCell align="left" width="149px">
                <Typography color="#344054" fontSize={14}>
                  <Chip label={row.status} size="small" />
                </Typography>
              </TableCell>
              <TableCell align="left" width="131px">
                <Typography color="#344054" fontSize={14}>
                  <div>
                    <Typography color="#101928" fontSize={14}>
                      {row.etaDate}
                    </Typography>
                    <Typography color="#101928" fontSize={14}>
                      {row.etaTime}
                    </Typography>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                    "aria-valuetext": "Page 1 of 30",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
