// Function to convert camelCase to snake_case
const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
};

// Function to convert an object's keys from camelCase to snake_case
export const convertKeysToSnakeCase = (obj: any) => {
  const newObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = toSnakeCase(key);
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
};

const STATUS_VALUE: Record<string, {}> = {
  completed: {
    bgcolor: '#ECFDF3',
    color: '#379E66',
  },
  pending: {
    color: '#F9A000',
    bgcolor: '#FFFAEB',
  },
  failed: {
    color: '#DA4733',
    bgcolor: '#FEF3F2',
  },
  expired: {
    color: '#DA4733',
    bgcolor: '#FEF3F2',
  },
};

export const getStatusStyle = (val: string): any => {
  return STATUS_VALUE[val?.toLowerCase()];
};
