import { Avatar, Box, Typography } from "@mui/material";
import move9jaLogo from "../assets/logo.svg";
import homeIcon from "../assets/home-icon.svg";
import eventIcon from "../assets/event-icon.svg";
import walletIcon from "../assets/wallet.svg";
import usersIcon from "../assets/users.svg";
import messageIcon from "../assets/message.svg";
import parkIcon from "../assets/parks.svg";
import location from "../assets/location.svg";
import routes from "../assets/routes.svg";
import settings from "../assets/settings.svg";
import ads from "../assets/ads.svg";
import promo from "../assets/promo.svg";
import { ArrowDropDown, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import profile from "../assets/profile.jpg";

const NextSideBar = () => {

  const checkStatus = localStorage.getItem('dashboardView')

  return (
    <Box sx={{ overflowY: 'scroll', scrollbarWidth: 'none' }} width="100%" height="100vh" borderRight="1px solid #E4E7EC" pt="24px">
      <Box height="603.26px">
        <Box p="8px 24px" mb="4px">
          <img src={move9jaLogo} alt="" width="162.75px" height="50.26px" />
        </Box>
        <Box p="0px 8px" mb="12px" borderBottom="1px solid #E4E7EC">
          <SideBarCards title="Dashboard" icon={homeIcon} path="/dashboard" />

          <SideBarCards title="Bookings" icon={walletIcon} path={checkStatus === "1" ? "/bookings/flight" : '/bookings/ride'} />

          <SideBarCards
            title="Transactions"
            icon={walletIcon}
            path="/transactions"
          />
          <SideBarCards title="Users" icon={messageIcon} path="/users" />
        </Box>

        <Box height="408.74px" />
        <Box p="0px 8px" mb="12px">
          <Box>
            <SideBarCards title="Settings" icon={settings} path="/settings" />
            <SideBarCards title="Create Ad" icon={ads} path="/create-ad" />
            <SideBarCards title="Promo" icon={promo} path="/promo" />
          </Box>
        </Box>
        <Box
          p="12px 24px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="12px"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Avatar sx={{ height: 40, width: 40 }} src={profile} />
            <div>
              <Typography color="#101928" fontSize={14} fontWeight={600}>
                David Fayemi
              </Typography>
              <Typography color="#475367" fontSize={14}>
                David@rayna.ui
              </Typography>
            </div>
          </div>
          <Logout sx={{ height: 20, width: 20 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default NextSideBar;

const SideBarCards = ({
  title,
  icon,
  path,
}: {
  title: string;
  icon: string;
  path: string;
}) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "12px 16px",
          gap: "12px",
          my: "4px",
          bgcolor:
            window.location.pathname === path ? "#E8FFF2" : "transparent",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        onClick={() => navigate(path)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            borderRadius: "4px",
          }}
        >
          <img src={icon} alt="" height={20} width={20} />
          <Typography fontSize={14}>{title}</Typography>
        </div>
      </Box>
    </Box>
  );
};
