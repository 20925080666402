function createData(
  date: string,
  amount: string,
  transId: string,
  desc: string,
  payment: string,
  method: "Successful" | "Pending"
) {
  return {
    date,
    amount,
    transId,
    desc,
    payment,
    method,
  };
}

export const rows = [
  createData(
    "Apr 12, 2023 | 09:32AM",
    "$200,000.00",
    "n90j903jm043jmp3",
    "Lagos to Ilorin",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 15, 2023 | 10:15AM",
    "$150,000.00",
    "k12j903jm045jmp4",
    "Ilorin to Abuja",
    "Credit Card",
    "Successful"
  ),
  createData(
    "Apr 18, 2023 | 11:00AM",
    "$75,000.00",
    "m34j903jm043jmp5",
    "Abuja to Kano",
    "Bank Transfer",
    "Pending"
  ),
  createData(
    "Apr 20, 2023 | 12:30PM",
    "$120,000.00",
    "o56j903jm043jmp6",
    "Kano to Lagos",
    "Cash",
    "Successful"
  ),
  createData(
    "Apr 25, 2023 | 01:45PM",
    "$250,000.00",
    "p78j903jm043jmp7",
    "Lagos to Port Harcourt",
    "Debit Card",
    "Successful"
  ),
  createData(
    "Apr 28, 2023 | 02:20PM",
    "$90,000.00",
    "q90j903jm043jmp8",
    "Port Harcourt to Enugu",
    "Cash",
    "Pending"
  ),
  createData(
    "May 01, 2023 | 03:00PM",
    "$60,000.00",
    "r12j903jm043jmp9",
    "Enugu to Owerri",
    "Cash",
    "Successful"
  ),
  createData(
    "May 03, 2023 | 04:10PM",
    "$80,000.00",
    "s34j903jm043jmp0",
    "Owerri to Calabar",
    "Bank Transfer",
    "Successful"
  ),
  createData(
    "May 05, 2023 | 05:30PM",
    "$95,000.00",
    "t56j903jm043jmp1",
    "Calabar to Ibadan",
    "Credit Card",
    "Pending"
  ),
  createData(
    "May 08, 2023 | 06:00PM",
    "$110,000.00",
    "u78j903jm043jmp2",
    "Ibadan to Abuja",
    "Debit Card",
    "Successful"
  ),
  createData(
    "May 10, 2023 | 07:45PM",
    "$140,000.00",
    "v90j903jm043jmp3",
    "Abuja to Lagos",
    "Cash",
    "Successful"
  ),
];
