import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

interface DashboardTopTitleProps {
  fontSize?: number;
  title: string;
  subtitle?: string;
  onClickFirst?: () => void;
  onClickSecond?: () => void;
  firstBtnIcon?: React.ReactNode;
  secondBtnIcon?: React.ReactNode;
  firstBtnTitle?: string;
  secondBtnTitle?: string;
  firstBtn?: boolean;
  secondBtn?: boolean;
  secondBgColor?: string;
}

const DashboardTopTitle = ({
  fontSize = 24,
  title,
  subtitle,
  onClickFirst,
  onClickSecond,
  firstBtnIcon,
  secondBtnIcon,
  firstBtnTitle,
  secondBtnTitle,
  firstBtn,
  secondBtn,
  secondBgColor = '#379E66',
}: DashboardTopTitleProps) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      mb='24px'
    >
      <div>
        <Typography fontSize={fontSize} fontWeight={600}>
          {title}
        </Typography>
        <Typography sx={{ color: '#667185' }} fontSize={14}>
          {subtitle}
        </Typography>
      </div>
      <div>
        {firstBtn && (
          <Button
            sx={{
              fontSize: 14,
              fontWeight: 400,
              color: '#000000',
              bgcolor: '#FFFFFF',
              mr: '12px',
              borderRadius: '8px',
              border: '1px solid #E4E7EC',
            }}
            variant='contained'
            startIcon={firstBtnIcon}
            onClick={onClickFirst}
          >
            {firstBtnTitle}
          </Button>
        )}
        {secondBtn && (
          <Button
            startIcon={secondBtnIcon}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              borderRadius: '8px',
              bgcolor: secondBgColor,
            }}
            variant='contained'
            onClick={onClickSecond}
          >
            {secondBtnTitle}
          </Button>
        )}
      </div>
    </Box>
  );
};

export default DashboardTopTitle;
