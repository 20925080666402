import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function StateChangerUI() {

    const goTo = useNavigate();
    const location = useLocation();
    const urlParameter = new URLSearchParams(location.search);
    const initialStateView = urlParameter.get('view') || 'interstate'

    const [selected, setSelected] = useState(initialStateView === 'interstate' ? 0 : initialStateView === 'flight' ? 1 : 2);
    const [nextSideBar, setNextSideBar] = useState(selected !== 0);


    useEffect(() => {
        const savedView = localStorage.getItem("dashboardView");
        if (savedView) {
            setSelected(parseInt(savedView));
            setNextSideBar(parseInt(savedView) !== 0);
        }
    }, []);


    const onClickButton = (selectedValue: number, view: string) => {
        setSelected(selectedValue);
        setNextSideBar(selectedValue !== 0);
        localStorage.setItem("dashboardView", selectedValue.toString());
        goTo(`/dashboard?view=${view}`);
    };

    return (
        <Box display="flex" alignItems="center" my="24px">
            <Button
                variant="contained"
                sx={{
                    width: "254.4px",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                    borderRadius: "8px",
                    color: selected === 0 ? "white" : "#667085",
                    bgcolor: selected === 0 ? "#379E66" : "white",
                }}
                onClick={() => onClickButton(0, "interstate")}
            >
                Interstate Trips
            </Button>
            <Button
                sx={{
                    width: "254.4px",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                    color: selected === 1 ? "white" : "#667085",
                    bgcolor: selected === 1 ? "#379E66" : "white",
                    borderRadius: "8px",
                }}
                variant="contained"
                onClick={() => onClickButton(1, "flight")}
            >
                Flight
            </Button>
            <Button
                sx={{
                    width: "254.4px",
                    fontWeight: 600,
                    fontSize: "14px",
                    height: "36px",
                    color: selected === 2 ? "white" : "#667085",
                    bgcolor: selected === 2 ? "#379E66" : "white",
                    borderRadius: "8px",
                }}
                variant="contained"
                onClick={() => onClickButton(2, "ride")}
            >
                Ride
            </Button>
        </Box>
    )
}
