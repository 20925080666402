import DashboardTopTitle from "../../components/DashboardTopTitle";
import downloadIcon from "../../assets/download.png";
import { AddCircleOutline } from "@mui/icons-material";
import { useState } from "react";
import DashboardLayout from "../../layout";
import TableSearch from "../../shared/TableSearch";
import ParkGridCard from "./ParkGridCard";
import Box from "@mui/material/Box";
import Information from "./SubCards/Information";
import Insights from "./SubCards/Insights";
import React from "react";
import Travels from "./SubCards/Travels";
import Media from "./SubCards/Media";
import Transactions from "./SubCards/Transactions";
import ParkManager from "./SubCards/ParkManager";
import ReUseAbleTabs from "../../shared/ReUseAbleTabs";
import { useNavigate } from "react-router-dom";

const tabs = [
  {
    label: "Information",
    path: "/parks/information",
    Component: <Information />,
  },
  {
    label: "Park Manager",
    path: "/parks/park-manager",
    Component: <ParkManager />,
  },
  {
    label: "Insights",
    path: "/parks/insights",
    Component: <Insights />,
  },
  {
    label: "Transactions",
    path: "/parks/transactions",
    Component: <Transactions />,
  },
  {
    label: "Media",
    path: "/parks/media",
    Component: <Media />,
  },
  {
    label: "Travels",
    path: "/parks/travels",
    Component: <Travels />,
  },
];

const Parks = () => {
  const [value, setValue] = React.useState(0);
  const [showNextPage, setShowNextPage] = useState(true);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <DashboardTopTitle
        title="Park Managers"
        subtitle="Showing data over the last 30 days"
        firstBtn
        firstBtnIcon={
          <img src={downloadIcon} alt="" height="20px" width="20px" />
        }
        firstBtnTitle="Export CSV"
        secondBtn
        secondBtnIcon={<AddCircleOutline />}
        secondBtnTitle="Register New Park"
        onClickSecond={() => navigate("/create-park")}
      />
      <TableSearch noBgColor />

      {showNextPage && <ParkGridCard onClick={() => setShowNextPage(false)} />}
      {!showNextPage && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "auto" }}>
            <Box>
              <ReUseAbleTabs tabs={tabs} />
            </Box>
          </Box>
        </Box>
      )}
    </DashboardLayout>
  );
};

export default Parks;
