import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

export interface Column {
  id: string;
  label: string | JSX.Element;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any) => React.ReactNode;
}

interface ReUseAbleTableProps {
  columns: Column[];
  rows: any[];
  onClick?: (row: any) => void;
}

const ReUseAbleTable: React.FC<ReUseAbleTableProps> = ({
  columns,
  rows,
  onClick,
}) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ borderRadius: '10px', border: '1px solid #E4E7EC' }}
    >
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead sx={{ bgcolor: '#F9FAFB' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align || 'left'}
                style={{ minWidth: column.minWidth }}
                sx={{ fontSize: 12, color: '#344054', height: '45px' }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
              }}
              onClick={() => onClick && onClick(row)}
            >
              {columns.map((column) => {
                const value = row[column.id];
                return (
                  <TableCell
                    sx={{ fontSize: '12px' }}
                    key={column.id}
                    align={column.align || 'left'}
                  >
                    {column.format ? column.format(value) : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReUseAbleTable;
