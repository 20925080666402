import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Box, Checkbox, Chip, Typography } from "@mui/material";
import TableSearch from "../../../shared/TableSearch";

function createData(
  name: string,
  id: string,
  email: string,
  type: string,
  amt: string,
  earned: string
) {
  return { name, id, email, type, amt, earned };
}
const rows = [
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
  createData(
    "Osim Uka",
    "#23444",
    "osimukaokpan@gmail.com",
    "User",
    "NGN 34,890.00",
    "One Time"
  ),
];

export default function ReferralTable({ onClick }: { onClick: () => void }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <TableSearch />
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell height="45px" width="357px">
              <Box display="flex" alignItems="center">
                <Checkbox />
                <Typography color="#344054" fontSize={12}>
                  Name
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              ID
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="242px"
            >
              Email
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="center"
              width="166px"
            >
              Type
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              Amount Earned
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <TableCell component="th" scope="row" width="357px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Checkbox />
                  <Avatar />
                  <Typography color="#101928" fontSize={14}>
                    {row.name}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.id}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="left" width="242px">
                <Typography color="#344054" fontSize={14}>
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell align="center" width="166px">
                <Typography color="#344054" fontSize={14}>
                  {row.type}
                </Typography>
              </TableCell>
              <TableCell align="left" width="166px">
                <Typography color="#344054" fontSize={14}>
                  {row.amt}
                </Typography>
                <Typography color="#344054" fontSize={14}>
                  {row.earned}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
