import React from "react";
import DashboardLayout from "../layout";
import Box from "@mui/material/Box";
import DashboardTopTitle from "../components/DashboardTopTitle";
import { AddCircleOutlineRounded, LocationCity } from "@mui/icons-material";
import DashboardTableEvents from "../components/DashboardTableEvents";
import { Chip, Typography } from "@mui/material";
import bus from "../assets/bus.svg";
import TableSearch from "../shared/TableSearch";
import Round from "../assets/round.png";
import Flash from "../assets/flash.png";
import { ReactComponent as OrderArrow } from "../assets/order-arrow.svg";

const AvaliableRoutes = () => {
  return (
    <DashboardLayout>
      <Box>
        <DashboardTopTitle
          title="Avaliable Routes"
          onClickFirst={() => {}}
          secondBtnIcon={<AddCircleOutlineRounded />}
          secondBtnTitle="New Appointment"
          secondBtn
        />
        <TableSearch />
        <DashboardTableEvents>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            gap="8px"
            p="8px"
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((_) => (
              <Box
                border="1px solid #E4E7EC"
                borderRadius="8px"
                p="12px"
                height="162px"
                width="auto"
                bgcolor="#FFFFFF"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography fontWeight={700} color="#379E66" fontSize={12}>
                      6:45am
                    </Typography>
                    <Typography fontSize={12}>
                      Young Legacy Park,
                      <br /> Iyana- Ipaja
                    </Typography>
                    <Typography fontSize={12} color="#98A2B3">
                      Lagos
                    </Typography>
                  </div>
                  <OrderArrow />
                  <div style={{ textAlign: "right" }}>
                    <Typography fontWeight={700} color="#379E66" fontSize={12}>
                      12:03pm
                    </Typography>
                    <Typography fontSize={12}>
                      Abukbakar Gamjuma,
                      <br /> Market Park
                    </Typography>
                    <Typography fontSize={12} color="#98A2B3">
                      Ilorin
                    </Typography>
                  </div>
                </Box>
                <div
                  style={{
                    gap: "8px",
                    alignItems: "center",
                    display: "flex",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                >
                  <img src={bus} alt="" />
                  <Chip
                    sx={{
                      fontWeight: 600,
                    }}
                    label="4 seats left"
                    size="small"
                  />
                  <Chip
                    sx={{
                      fontWeight: 600,
                    }}
                    label="12 seater"
                    size="small"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography fontSize={12} fontWeight={700}>
                    NGN 35,895 - 40,904
                  </Typography>
                  <div
                    style={{
                      gap: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      sx={{
                        bgcolor: "#FFFAEB",
                        color: "#B54708",
                        fontWeight: 600,
                      }}
                      label="2km"
                      size="small"
                      icon={<img src={Flash} height="16px" width="16px" />}
                    />
                    <Chip
                      sx={{
                        bgcolor: "#EEF4FF",
                        color: "#3538CD",
                        fontWeight: 600,
                      }}
                      label="Standard"
                      size="small"
                      icon={<img src={Round} height="16px" width="16px" />}
                    />
                  </div>
                </div>
              </Box>
            ))}
          </Box>
        </DashboardTableEvents>
      </Box>
    </DashboardLayout>
  );
};

export default AvaliableRoutes;
