import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  TextField,
  Typography,
} from "@mui/material";
import { Filter, MoreVert, Search, UnfoldMore } from "@mui/icons-material";

import React from "react";
import profile from "assets/profile.jpg";
import TableSearch from "shared/TableSearch";

function createData(
  name: string,
  total: string,
  email: string,
  date: string,
  status: string
) {
  return {
    name,
    total,
    email,
    date,
    status,
  };
}
const rows = [
  createData(
    "Osim Uka",
    "$200,000.00",
    "osimukaokpan@gmail.com",
    "Apr 12, 2023 | 09:32AM",
    "Completed"
  ),
  createData(
    "Osim Uka",
    "$200,000.00",
    "osimukaokpan@gmail.com",
    "Apr 12, 2023 | 09:32AM",
    "Completed"
  ),
  createData(
    "Osim Uka",
    "$200,000.00",
    "osimukaokpan@gmail.com",
    "Apr 12, 2023 | 09:32AM",
    "Completed"
  ),
];

export default function TransactionTable({ onClick }: { onClick: () => void }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "10px", border: "1px solid #E4E7EC" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ bgcolor: "#F9FAFB" }}>
          <TableRow>
            <TableCell height="45px" width="311px">
              <Typography color="#344054" fontSize={12}>
                Name
              </Typography>
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="137px"
            >
              Total Remitted
            </TableCell>

            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="188px"
            >
              Date
            </TableCell>
            <TableCell
              height="45px"
              color="#344054"
              sx={{ fontSize: 12 }}
              align="left"
              width="166px"
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={onClick}
            >
              <TableCell component="th" scope="row" width="311px">
                <Box display="flex" alignItems="center" gap="12px">
                  <Checkbox />
                  <Avatar
                    sx={{
                      height: "40px",
                      width: "40px",
                      fontWeight: "bold",
                      color: "#000",
                      bgcolor: "#E8FFF2",
                    }}
                  >
                    E
                  </Avatar>
                  <Box>
                    <Typography color="#101928" fontSize={14}>
                      {row.name}
                    </Typography>
                    <Typography color="#344054" fontSize={14}>
                      {row.email}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>

              <TableCell align="left" width="137px">
                <Box>
                  <Typography color="#344054" fontSize={14}>
                    {row.total}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell align="left" width="166px">
                <Typography color="#344054" fontSize={14}>
                  {row.date}
                </Typography>
              </TableCell>
              <TableCell align="left" width="72px">
                <Chip
                  label={row.status}
                  size="small"
                  sx={{
                    bgcolor: "#E8FFF2",
                    color: "#379E66",
                    fontSize: "14px",
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
