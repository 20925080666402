import React from "react";
import DashboardLayout from "../layout";
import Box from "@mui/material/Box";

const CreateAd = () => {
  return (
    <DashboardLayout>
      <Box>CreateAd</Box>
    </DashboardLayout>
  );
};

export default CreateAd;
