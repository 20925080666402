import { MoreVert } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import vendor from "assets/vendor.svg";
import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface DataPoint {
  name: string;
  uv: number;
  pv: number;
  mv: number;
  amt: number;
}

const data: DataPoint[] = [
  {
    name: "A",
    uv: 60,
    pv: 40,
    amt: 120,
    mv: 20,
  },
  {
    name: "B",
    uv: 62,
    pv: 41,
    amt: 120,
    mv: 24,
  },
  {
    name: "C",
    uv: 61,
    pv: 44,
    amt: 120,
    mv: 22,
  },
  {
    name: "D",
    uv: 63,
    pv: 40,
    amt: 120,
    mv: 50,
  },
  {
    name: "E",
    uv: 64,
    pv: 47,
    amt: 120,
    mv: 25,
  },
  {
    name: "F",
    uv: 65,
    pv: 48,
    amt: 120,
    mv: 26,
  },
  {
    name: "G",
    uv: 66,
    pv: 48,
    amt: 100,
    mv: 28,
  },
  {
    name: "EE",
    uv: 68,
    pv: 49,
    amt: 120,
    mv: 29,
  },
  {
    name: "FD",
    uv: 70,
    pv: 50,
    amt: 120,
    mv: 30,
  },
  {
    name: "GF",
    uv: 73,
    pv: 53,
    amt: 100,
    mv: 31,
  },
  {
    name: "wE",
    uv: 77,
    pv: 35,
    amt: 120,
    mv: 29,
  },
  {
    name: "sF",
    uv: 78,
    pv: 33,
    amt: 120,
    mv: 28,
  },
  {
    name: "Ga",
    uv: 77,
    pv: 45,
    amt: 100,
    mv: 27,
  },
];

const VendorBreakdown = () => {
  const chartStyle = { fontFamily: "Sora" };
  return (
    <Box
      width="100%"
      height="514px"
      borderRadius="12px"
      bgcolor="#FFFFFF"
      border="1px solid #E4E7EC"
    >
      <Box
        height="93px"
        p="24px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <img src={vendor} alt="" height={56} width={56} />
          <div>
            <Typography fontWeight={600} fontSize={18}>
              Vendors breakdown
            </Typography>
            <Typography fontSize={14}>
              Keep track of vendoers and their security ratings.
            </Typography>
          </div>
        </div>
        <MoreVert />
      </Box>
      <Divider />
      <Box>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            style={chartStyle}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis dataKey="name" tickLine={false} />
            <YAxis ticks={[20, 40, 60, 80, 100, 120, 140]} tickLine={false} />
            <Tooltip />
            <Line
              strokeWidth={3}
              type="natural"
              dataKey="pv"
              stroke="#379E66"
              activeDot={{ r: 8 }}
              dot={false}
            />
            <Line
              strokeWidth={3}
              dot={false}
              activeDot={{ r: 8 }}
              type="natural"
              dataKey="uv"
              stroke="#B692F6"
            />
            <Line
              strokeWidth={3}
              type="natural"
              dataKey="mv"
              stroke="#6941C6"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default VendorBreakdown;
