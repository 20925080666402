import http from "services/http";

export interface IPark {
  name: string;
  address: string;
  busStop: string;
  state: string;
}

export const createPark = async (body: IPark) => {
  const res = http.post("/admin/createpark", body);
  return (await res).data;
};

export const createParkManager = async (body: any) => {
  const res = http.post("/admin/createparkmanager", body);
  return (await res).data;
};

export const viewAllParks = async () => {
  const res = http.get("/admin/allparks");
  return (await res).data;
};
