import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar, Box, Checkbox, Chip, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import TableSearch from '../shared/TableSearch';
import profile from '../assets/profile.jpg';

function createData(name: string, email: string, phone: string) {
  return { name, email, phone };
}
const rows = [
  createData('Osim Uka', 'osimukaokpan@gmail.com', '+234 906 315 9265'),
  createData('Osim Uka', 'osimukaokpan@gmail.com', '+234 906 315 9265'),
  createData('Osim Uka', 'osimukaokpan@gmail.com', '+234 906 315 9265'),
];

export default function CustomTable({ onClick }: { onClick: () => void }) {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{ borderRadius: '10px', border: '1px solid #E4E7EC' }}
    >
      <TableSearch />
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead sx={{ bgcolor: '#F9FAFB' }}>
          <TableRow>
            <TableCell height='45px' width='311px'>
              <Box display='flex' alignItems='center'>
                <Checkbox />
                <Typography color='#344054' fontSize={12}>
                  Name
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              height='45px'
              color='#344054'
              sx={{ fontSize: 12 }}
              align='left'
              width='242px'
            >
              Email
            </TableCell>
            <TableCell
              height='45px'
              color='#344054'
              sx={{ fontSize: 12 }}
              align='left'
              width='242px'
            >
              Phone
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                cursor: 'pointer',
              }}
              onClick={onClick}
            >
              <TableCell component='th' scope='row' width='311px'>
                <Box display='flex' alignItems='center' gap='12px'>
                  <Checkbox />
                  <Avatar
                    sx={{ height: '40px', width: '40px' }}
                    src={profile}
                  />
                  <Typography color='#101928' fontSize={14}>
                    {row.name}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell align='left'>
                <Typography color='#344054' fontSize={14}>
                  {row.email}
                </Typography>
              </TableCell>

              <TableCell align='left'>{row?.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
